import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import useWindowDimensions from "../../helpers/WindowWidth";
import { history } from "../../helpers/history";

const Verification = () => {
  const { height, width } = useWindowDimensions();
  const [success, setSuccess] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const status = params.get("Status");
  const userId = params.get("lookup");

  useEffect(() => {
    if (status === "true")
      setSuccess(true);
    else
      setSuccess(false)
  }, []);
  const gotoidentitypage=()=>{
    //history.push("/identityVerification", {userId,updatestate:"To the Card Holder Account"});
    history.push("/login");
  }
  return (
    <div className="row minvh-100">
      {width > 767 && <BoPaymentWrapper
        pagename={["Create An", <br />, "Account"]}
        pagemsg={""}
      />}


      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div className="row">
          {success && (
            <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="icon-alert">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h3 className="mb-4">Great, All Done!</h3>
                <h6>You have Successfully completed the process</h6>
                <div className="form-group submit-field mt-40">
                  <Link to="/login" className="btn btn-large w-100">
                    Go to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          )}
          {!success && (
            <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="icon-alert">
                  <span className="icon-error">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h3 className="mb-4">Verification Unsuccessful</h3>
                <h6>
                  Your document could not be verified. You need to get in touch
                  with our Support team.
                </h6>
                <div className="form-group submit-field mt-40">
                  <button to="#" className="btn btn-large w-100" onClick={gotoidentitypage}>
                    Try again with ID verification
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verification;
