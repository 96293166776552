import { Fragment, useState, useRef, useEffect } from "react";
import useWindowDimensions from "../../helpers/WindowWidth";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import { useSelector } from "react-redux";
import transactionService from "../../services/transaction.service";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import SearchGridSendMoney from "./SearchGridSendMoney";
import logo from "../../assets/images/tick.png";
import Help from "./Help";

const P2PTransfer = (props) => {
  const CheckNegativeAmount = (value) => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount cannot be negative</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const CheckZeroAmount = (value) => {
    let obj = parseFloat(value);
    if (obj === 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount must be greater than 0!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const accountNumberRequired = (value) => {
    if (!value) {
      document.getElementById("id_AccountNumber").classList.add("has_error");
      return <span className="help-block">Token ID is required!</span>;
    } else
      document.getElementById("id_AccountNumber").classList.remove("has_error");
  };
  const AmountRequired = (value) => {
    if (!value) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount is required!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const { user: currentUser } = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const { height, width } = useWindowDimensions();
  const [boloader, setBoLoader] = useState(false);
  const [UnMaskedCardNumber, setUnMaskedCardNumber] = useState();
  const [TokenId, setTokenId] = useState();
  const [Amount, setAmount] = useState();
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));
  //Payment processor selection
  const [CardHashId, setCardHashId] = useState();
  const [PaymentProcessor, setPaymentProcessor] = useState(
    String(currentUser.user.PaymentProcessor)
  );
  const [CurrencyCode, setCurrencyCode] = useState(currentUser?.user?.ClientCurrency);

  const [dynamicClass, setDynamicClass] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setconfirmModalShow] = useState(false);
  const [favouritePayee, setFavouritePayee] = useState(false);
  //For Nium Confirmations Properties
  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [SenderCustomerHashId, setSenderCustomerHashId] = useState();
  const [SenderWalletHashId, setSenderWalletHashId] = useState();
  const [ReceiverWalletHashId, setReceiverWalletHashId] = useState();
  const [ReceiverCardHashId, setReceiverCardHashId] = useState();
  const [SenderCardHashId, setSenderCardHashId] = useState();
  //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
 
  var fontStyle = {
    fontSize: 13,
  };
  useEffect(() => {
    if (props.classparam !== undefined) {
      setDynamicClass("mt-40");
      setTokenId(props.cardnumber);
    } else setDynamicClass("col-xl-6 col-lg-8 col-md-12 bo-main-content");
  }, []);
  const onChangeAccountNumber = (e) => {
    const AccountNumber = e.target.value;
    // if (AccountNumber != "") {
    // let value = parseFloat(AccountNumber);
    setTokenId(String(AccountNumber));
    // }
  };
  const onChangeAmount = (e) => {
    const Amount = e.target.value;
    if (Amount != "") {
      let value = parseFloat(Amount);
      setAmount(value);
    }
  };
  const onChangeCardFeeCurrencyCode = (e) => {
    const name = e.target.selectedOptions[0].value;
    //const status = e.value;
    //let statusvalue = parseInt(status);
    setCurrencyCode(name);
  };
  const CheckCardDetails = (e) => {
    if (e) e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      transactionService
        .getVerificationPayee(TokenId, Amount, CurrencyCode, UserId)
        .then(
          (response) => {
            setBoLoader(false);
            if (response && response.data && response.data.payload) {
              setName(response.data.payload.result.name);
              setEmail(response.data.payload.result.email);
              setReceiverWalletHashId(
                response.data.payload.result.receiverWalletHashId
              );
              setSenderCustomerHashId(
                response.data.payload.result.senderCustomerHashId
              );
              setSenderWalletHashId(
                response.data.payload.result.senderWalletHashId
              );
              setReceiverCardHashId(response.data.payload.result.receiverCardHashId);
              setSenderCardHashId(response.data.payload.result.senderCardHashId);
              setconfirmModalShow(true);
            }
          },
          (error) => {
            let msgBody = error.response.data.message
              ? error.response.data.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
  };
  const saveP2pDetails = (e) => {
    let CardType=currentUser?.user?.IsPhysicalCard ?"GPR_PHY":"GPR_VIR"??"";
    if (e) e.preventDefault();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      transactionService
        .saveP2PTransfer(
          TokenId,
          Amount,
          CurrencyCode,
          UserId,
          Name,
          Email,
          SenderCustomerHashId,
          SenderWalletHashId,
          ReceiverWalletHashId,
          ReceiverCardHashId,
          SenderCardHashId,
          CardType,

        )
        .then(
          (response) => {
            setBoLoader(false);
            if (response && response.data && response.data.payload) {
              if (response.data.payload.result.item2 === "Success") {
                setconfirmModalShow(false);
                setModalShow(true);
                if (props && props.FavouritesList) {
                  for (var i = 0; i < props.FavouritesList.length; i++) {
                    if (props.FavouritesList[i].TokenId === TokenId) {
                      setFavouritePayee(true);
                    }
                  }
                }
              }
            }
          },
          (error) => {
            setBoLoader(false);
            setconfirmModalShow(false);
            setModalShow(false);
            let msgBody = error.response.data.message
              ? error.response.data.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
    //setconfirmModalShow(false);
    //setModalShow(true);
  };
  const addToFavourite = (e) => {
    if (e) e.preventDefault();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      transactionService
        .addToFavourite(
          TokenId,
          Amount,
          CurrencyCode,
          UserId,
          Name,
          Email,
          SenderCustomerHashId,
          SenderWalletHashId,
          ReceiverWalletHashId,
          
        )
        .then(
          (response) => {
            setBoLoader(false);
            if (response && response.data && response.data.payload.succeeded) {
              setFavouritePayee(true);
            }
          },
          (error) => {
            setBoLoader(false);
            let msgBody = error.response.data.message
              ? error.response.data.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
    setconfirmModalShow(false);
    setModalShow(true);
  };
  const closeP2pModal = () => {
    setModalShow(false);
    setconfirmModalShow(false);
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const renderDetails = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div class="icon-alert icon-alert-popup">
            <span class="icon-successful">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <h2>Transfered</h2>
          <div className="mt-16">
            <div>
              <Form onSubmit={closeP2pModal}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Amount has been sent successfully to.</p>
                  </div>
                  <div className="col-md-12">
                    <ul className="ul-card-details-popup mt-20">
                      <li className="d-flex justify-content-between mb-16">
                        <label>Token ID</label>
                        <span>{TokenId}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Amount</label>
                        <span>{Amount}</span>
                      </li>
                      {/* <li className="d-flex justify-content-between mb-16">
                        <label>Currency Code</label>
                        <span>{CurrencyCode}</span>
                      </li> */}
                      <li className="d-flex justify-content-between mb-16">
                        <label>Name</label>
                        <span>{Name}</span>
                      </li>
                      <li className="d-flex justify-content-between mb-16">
                        <label>Email</label>
                        <span>{Email}</span>
                      </li>
                    </ul>
                    <div class="d-flex align-items-center justify-content-center a-star">
                      {favouritePayee === true ? (
                        <div>
                          <img src={logo} />
                          <label>Favourites Added </label>
                        </div>
                      ) : favouritePayee === false ? (
                        <a onClick={addToFavourite}>
                          <i class="icon-star-outline"></i>Add to Favorites
                        </a>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div class="form-group submit-field text-center mt-40">
                      {/* <button
                        class="btn btn-outline mr-19"
                        onClick={closeP2pModal}
                      >
                        Cancel
                      </button> */}
                      <button class="btn btn-large" onclick={closeP2pModal}>
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderConfirmDetails = () => {
    return (
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Confirmation</h2>
          <div className="mt-16">
            <div>
              <div className="row">
                <div className="col-md-12">
                  <p>Please Confirm Details of Transactions.</p>
                </div>
                <div className="col-md-12">
                  <ul className="ul-card-details-popup mt-20">
                    <li className="d-flex justify-content-between mb-16">
                      <label>Token ID</label>
                      <span>{TokenId}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Amount</label>
                      <span>{Amount}</span>
                    </li>
                    {/* <li className="d-flex justify-content-between mb-16">
                      <label>Currency Code</label>
                      <span>{CurrencyCode}</span>
                    </li> */}
                    <li className="d-flex justify-content-between mb-16">
                      <label>Name</label>
                      <span>{Name}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-16">
                      <label>Email</label>
                      <span style={fontStyle}>{Email}</span>
                    </li>
                  </ul>
                  <div class="form-group submit-field d-flex justify-content-between mt-40">
                    <button
                      class="btn btn-outline mr-19"
                      onClick={closeP2pModal}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <a class="btn btn-large" onClick={saveP2pDetails}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
      let t = object.target.value;
      if(t.includes("."))
      object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    }
  return (
    <Fragment>
      <div className={dynamicClass}>
        <BoLoader loading={boloader} />
        <section>
          <h3>Send Money</h3>
          {/* className="form-send-money" */}
          <Form onSubmit={CheckCardDetails} ref={form}>
            <div className="row mt-20">
              <div className="col-md-12">
                <div className="form-group" id="id_AccountNumber">
                  <label for="email">Token ID*</label>
                  <div className="form-controls">
                    <Input
                      type="number"
                      className="form-control"
                      onWheel={(e) => e.target.blur()}
                      name="AccountNumber"
                      maxLength = "10"
                      onInput={maxLengthCheck}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      value={TokenId}
                      onChange={onChangeAccountNumber}
                      validations={[accountNumberRequired]}
                    />
                    <span className="icon-card-check _ico"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" id="id_Amount">
                  <label for="email">Amount*</label>
                  <div className="form-controls">
                    <Input
                      type="number"
                      className="form-control"
                      name="Amount"
                      maxLength = "9"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onInput={maxLengthCheck}
                      value={Amount}
                      onChange={onChangeAmount}
                      validations={[AmountRequired, CheckNegativeAmount,CheckZeroAmount]}
                    />
                    <span className="icon-money clr-dustpink _ico"></span>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label>Currency Code*</label>
                  <div className="form-controls">
                    <select
                      className="form-control no-icon"
                      onChange={onChangeCardFeeCurrencyCode}
                    >
                      {PaymentProcessor === "MSwipe" ? (
                        <option select="selected" value="USD">
                          USD
                        </option>
                      ) : (
                        <option select="selected" value="EUR">
                          EUR
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <div className="form-group submit-field">
                  <button className="btn btn-large w-100">Send Money</button>
                </div>
              </div>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          {props.calledfrom !== "dashboard" && (
            <SearchGridSendMoney
              placeholdervalue="Search Send Money"
              headersdata={["Name", "Token Id", "Date", "Amount"]}
              screenname="clients"
            />
          )}
        
        {/* {props.calledfrom === "dashboard" ?(""):(<Help/>)}   */}
        </section>
        <div>
          <ModelPopup
            key={1}
            show={modalShow}
            onHide={() => setModalShow(false)}
            modelheading={""}
            modelbody={renderDetails()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
          <ModelPopup
            key={2}
            show={confirmModalShow}
            onHide={() => setconfirmModalShow(false)}
            modelheading={""}
            modelbody={renderConfirmDetails()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
        </div>
        <DialoguePopup
          show={dialougeModalShow}
          onHide={() => setDialougeModalShow(false)}
          modelheading={modelhdng}
          modelbody={modelbdy}
          modelicon={MdlIcon}
          backdrop="static"
          keyboard={false}
        />
      </div>
    </Fragment>
  );
};

export default P2PTransfer;
