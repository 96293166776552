import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.CardHolderApiUrl;
const getcardholderDetail = (CardHolderId) => {
  return axios
    .get(API_URL + "getcardholderDetail?CardHolderId=" + CardHolderId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const updateCardHolderStatus = (cardholderId, isActive) => {
  return axios
    .post(
      API_URL +
        "updateStatus?cardholderId=" +
        cardholderId +
        "&isActive=" +
        isActive,
      {},
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const gettransactiondata = () => {
  return axios
    .post(API_URL + "getcardholderDetail", {}, { headers: authHeader() })
    .then((res) => {
      return res;
    });
};
const cardHolderService = {
  getcardholderDetail,
  updateCardHolderStatus,
  gettransactiondata,
};
export default cardHolderService;
