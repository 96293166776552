import React, { useState, useRef, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { useSelector } from "react-redux";

import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import { history } from "../../helpers/history";
import { Link } from "react-router-dom";
import CardDetails from "./CardDetails";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";

const ChangePinCardDetails = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(currentUser?.user?.UserId);
  const [TokenId, setToken] = useState(props?.tokenId);
  const userType = currentUser?.user?.PaymentProcessor;

  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalerrorShow, setModalerrorShow] = useState(false);
  const [currentpin, setcurrentpin] = useState("");
  const [newpin, setnewpin] = useState("");
  const [confirmpin, setconfirmpin] = useState("");
  const form = useRef();
  const checkBtn = useRef();
    //Loader
    const [boloader, setBoLoader] = useState(false);
      //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  let passwordValue = "";
  const newRequired = (value) => {
    passwordValue = value;
    if (!value) {
      document.getElementById("id_new_pass").classList.add("has_error");
      return <span className="help-block">New Pin is Required</span>;
    } else document.getElementById("id_new_pass").classList.remove("has_error");
  };
  const confirmRequired = (value) => {
    if (!value) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return <span className="help-block">Confirm Pin is Required</span>;
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };

  const vpassword = (value) => {
    if (value !== passwordValue) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return (
        <span className="help-block">
          The password and confirm pin must match
        </span>
      );
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };
  const onClickReset = () => {
    setModalsuccessfullShow(true);
    //alert("this is Reset");
  };
  const onClickChange = () => {
    if(currentUser?.user?.IssuerName === "IssuerG"){
      setBoLoader(true)
      cardholdercardsObj
      .changeCardHolderPinIssuerB( newpin,
        UserId,)
      .then((response) => {
        setBoLoader(false)
        setModalsuccessfullShow(true);
        if (!response?.data?.isError) {
          if (response?.data?.payload?.succeeded) {
          }
        }
      })
      .catch((error) => { 
        setBoLoader(false)
        let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          window.location.reload();
          setBoLoader(false);
        } else {
          setBoLoader(false);
        }
      })();
      });
      // alert("this is Change");
    }
    if(currentUser?.user?.IssuerName === "IssuerB4B"){
      setBoLoader(true)
      cardholdercardsObj
      .changeCardHolderPinIssuerB4B( newpin,
        UserId,)
      .then((response) => {
        setBoLoader(false)
        setModalsuccessfullShow(true);
        if (!response?.data?.isError) {
          if (response?.data?.payload?.succeeded) {
          }
        }
      })
      .catch((error) => { 
        setBoLoader(false)
        let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          window.location.reload();
          setBoLoader(false);
        } else {
          setBoLoader(false);
        }
      })();
      });
      // alert("this is Change");
    }
    else{
      setBoLoader(true)
      cardholdercardsObj
      .changeCardHolderPin( newpin,
        props.tokenId,
        UserId,)
      .then((response) => {
        setBoLoader(false)
        setModalsuccessfullShow(true);
        if (!response.data.isError) {
          if (response.data.payload.succeeded) {
          }
        }
      })
      .catch((error) => { setBoLoader(false)
        let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          window.location.reload();
          setBoLoader(false);
        } else {
          setBoLoader(false);
        }
      })();
      });
      // alert("this is Change");
    }

  };
  const onChangeCurrentPin = (e) => {
    const value = e.target.value;
    setcurrentpin(value);
  };
  const onChangeNewPin = (e) => {
    const value = e.target.value;

    setnewpin(value);
  };
  const onChangeConfirmPin = (e) => {
    const value = e.target.value;
    setconfirmpin(value);
  };
  const onHideModal = () => {
    setcurrentpin("");
    setnewpin("");
    setconfirmpin("");
    props.onHide();
  };
  const handleRegister = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (props.calledfrom === "reset") {
        onClickReset();
        //props.onHide();
      } else {
        onClickChange();
        props.onHide();
      }
    }
  };
  const changeCardHolderPin = () => {
    cardholdercardsObj
      .changeCardHolderPin(currentpin, newpin, confirmpin)
      .then((response) => {
        if (!response.data.isError) {
          if (response.data.payload.succeeded) {
          }
        }
      })
      .catch((err) => {});
  };
  const gotoDashboard = () => {
    props.onHide();
    history.push("dashboard");
  };

  return (
    <Fragment>
        <BoLoader loading={boloader} />
      <ModelPopup
        key={6}
        show={props.show}
        onHide={()=>{onHideModal();}}
        calledfrom={props.calledfrom}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall "
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={
          <Fragment>
           
          <div className="row">
            <div className="col-md-12">
              {props.calledfrom === "reset" ? (
                <h2 className="mx-xl-5"> Reset Card PIN</h2>
              ) : (
                <h2 className="mx-xl-5">Change Card PIN</h2>
              )}
 
              <div className="mt-20">
                <div
                  id="resetCardPin"
                  className="carousel slide mb-32"
                  data-bs-ride="carousel"
                >
                  <CardDetails calledfrom="updatepin"/>
                  {/* <div className="carousel-inner"> */}
                    {/* <div className="carousel-item active"> */}
                   
                    {/* </div> */}
                    {/* <div className="carousel-item">
                      <img className="d-block w-100" src={card} alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={card} alt="..." />
                    </div> */}
                     
                  {/* </div> */}
                  {/* <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#myCards"
                    data-bs-slide="prev"
                  >
                    <span className="prev-icon d-flex align-items-center justify-content-center">
                      <img src={arrowleft} aria-hidden="true" alt="arrow" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button> */}
                  {/* <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#myCards"
                    data-bs-slide="next"
                  >
                    <span className="next-icon d-flex align-items-center justify-content-center">
                      <img src={arrowright} aria-hidden="true" alt="arrow" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button> */}
                </div>
                <div>
                  <Form onSubmit={handleRegister} ref={form}>
                    <div className="row">
                     
                      <div className="col-md-12">
                        <div className="form-group" id="id_new_pass">
                          <label for="email">New Card PIN*</label>
                          <div className="form-controls">
                            <Input
                              type="password"
                              className="form-control no-icon"
                              value={newpin}
                              onChange={onChangeNewPin}
                              validations={[newRequired]}
                              placeholder="Enter New 4 Digit PIN"
                              maxLength={4}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" id="id_confirm_pass">
                        <div className="form-group">
                          <label for="email">Confirm Card PIN*</label>
                          <div className="form-controls">
                            <Input
                              type="password"
                              className="form-control  no-icon"
                              value={confirmpin}
                              onChange={onChangeConfirmPin}
                              validations={[confirmRequired, vpassword]}
                              placeholder="Confirm 4 Digit PIN"
                              maxLength={4}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-between mt-24">
                          <Link
                            to="#"
                            className="btn btn-outline mr-19"
                            data-bs-dismiss="modal"
                            onClick={onHideModal}
                          >
                            Cancel
                          </Link>
                          {props.calledfrom === "reset" ? (
                            <Link
                              to="#"
                              className="btn btn-large"
                              onClick={handleRegister}
                            >
                              Reset
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="btn btn-large"
                              onClick={handleRegister}
                            >
                              Change
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
          </Fragment>
        }
      />
      <ModelPopup
        key={4}
        show={modalerrorShow}
        onHide={() => {setModalerrorShow(false);onHideModal();}}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <div class="icon-alert icon-alert-popup">
                  <span class="icon-error">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span>
                </div>
                <h2>Error</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>You Entered incorrect current PIN.</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-24">
                            <Link to="#" class="btn btn-outline mr-19">
                              Back
                            </Link>
                            <Link to="#" class="btn btn-large">
                              Try Again
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => {setModalsuccessfullShow(false);onHideModal();}}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">

                          {currentUser?.user?.IssuerName === "IssuerG"? <p>Pin Changed Successfully.</p>
                          :<p>Request Made Successfully.</p>}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                //gotoDashboard();
                                document.getElementById("dashboardNavLink").click();
                              }}
                            >
                              Go to Dashboard
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
       <DialoguePopup
        show={dialougeModalShow}
        onHide={() => {
          setDialougeModalShow(false);
          setnewpin("");
          setconfirmpin("");
        }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        backdrop="static"
        keyboard={false}
      />
    </Fragment>
  );
};
export default ChangePinCardDetails;
