import { Fragment } from "react";
import CardDetails from "./CardDetails";
import CardLimit from "./CardLimits";
// import viewAllImg from "../../assets/images/icons/arrow-right.svg";
import { Env } from "../../enviroment/environment";
import CHDSendMoney from "./CHDSendMoney";
import useWindowDimensions from "../../helpers/WindowWidth";
import SearchGridCardTransactions from "./SearchGridCardTransactions";
import KapedVirtualAssistant from "./KapedVirtualAssistant";
import {useSelector } from "react-redux";
const API_URL = Env.CardsApiUrl;

const CHDashBoard = (props) => {
  const { height, width } = useWindowDimensions();
  const { user: currentUser } = useSelector(state => state.auth);
  var setpermission = localStorage.getItem("activeInactiveSendmoney");

  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <h3>
          Your Cards<span className="bo-badge bo-badge-large">1</span>
        </h3>
        <div className="row mt-20">
          {(currentUser?.user?.ClientId ===35) ?(<div className="col-xl-6 col-md-6 col-sm-6 mb-20">
            <CardDetails cht="Y" />
          </div>):(<div className="col-xl-6 col-md-6 col-sm-6 mb-20">
            <CardDetails cht="Y" />
          </div>)}
          
          <CardLimit />
        </div>

        {/* for mobile */}
        <div className="row">
        {props?.clientsid!=35 && (
<Fragment>
{setpermission!=="true" && (width <= 991 && <><CHDSendMoney isPermission={props?.sendmoneypermission}/> 
</>

)} 
</Fragment>
          )} 
        
        </div>

        <SearchGridCardTransactions
          placeholdervalue="Search Card Holder Transactions"
          headersdata={[
            "DATE",
            "MERCHANT NAME",
            "STATUS",
            // "CURRENCY",
            "AMOUNT",
            // "REF #",
            "VIEW",
          ]}
          screenname="cardholdertransactions"
        />
      </div>
    
         {
        props?.clientid!==35 ? (
          <Fragment>
             {setpermission!=="true" && (width > 991 && <CHDSendMoney  isPermission={props?.sendmoneypermission}/>
            )} 

            </Fragment>
        ):  
        (props?.clientid===35) ?(
          <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
        <div className="bo-right-sidebar-wrap">
       
          <div className="row align-items-center"></div>
          {/* <iframe  src="https://app.thimble.com/home?brokerid=PKAX8P5EB&widget_source=embed" style={{marginLeft:'-22px', height:'800px', width:"400px", borderRadius:"8px", marginTop:"-50px"}}>
          </iframe> */}
          </div>
          <div id="body">
          <KapedVirtualAssistant/>
      
    </div>
          </div>
          
        ):("")

      }
      
  
     
    

    </Fragment>
  );
};

export default CHDashBoard;
