import { Fragment } from "react";
import BoPaymentWrapper from "../components/BoPaymentLeftWrapper";
import { history } from "../helpers/history";

const AllDone = () => {
    return (
        <Fragment>
            <div className="row minvh-100">
            <BoPaymentWrapper
          pagename={["Create An", <br />, "Account"]}
          pagemsg={"To The Card Holder Account"}
        />
            <div className="col-md-7 bo-on-screens position-relative onbo-content-center minh-row-mbl">
                <div className="col-md-8 col-lg-5 mt-60">
                    <div className="icon-alert">
                        <span className="icon-loading">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </span>
                    </div>
                    <h3 className="mb-4">All done !</h3>
                    {/* <h6>
                Click to Login screen.
                </h6> */}
                    <div className="form-group submit-field mt-40">
                        <button
                            className="btn btn-large w-100"
                            onClick={() => history.push("/login")}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </Fragment>
    );
}
export default AllDone;