import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { history } from "../helpers/history";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import helpService from "../services/help.service";
import BoLoader from "../helpers/BoLoader";
const TitleRequired = (value) => {
  if (!value) {
    document.getElementById("helpTitle_frmGrp").classList.add("has_error");
    return <span className="help-block">Title is required</span>;
  } else {
    document.getElementById("helpTitle_frmGrp").classList.remove("has_error");
  }
};
const DescRequried = (value) => {
  if (!value) {
    document.getElementById("helpDescp_frmGrp").classList.add("has_error");
    return <span className="help-block">Description is required</span>;
  } else {
    document.getElementById("helpDescp_frmGrp").classList.remove("has_error");
  }
};
const Help = () => {
  const [successfullyFormSubmitted, setsuccessfullyFormSubmitted] =
    useState(false);
  const [helpTitle, sethelpTitle] = useState("");
  const [helpDescription, sethelpDescription] = useState("");
  const [boloader, setBoLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const submitHelpHandler = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      helpService
        .submitHelpQuery(currentUser.id, helpTitle, helpDescription)
        .then((resp) => {
          setBoLoader(false);
          if (resp.data.success) {
            setsuccessfullyFormSubmitted(true);
          }
        })
        .catch((err) => {
          setBoLoader(false);
          setsuccessfullyFormSubmitted(false);
        });
    }
  };
  const helpTitleChangeHandler = (e) => {
    sethelpTitle(e.target.value);
  };
  const helpDescriptionChangeHandler = (e) => {
    sethelpDescription(e.target.value);
  };
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      {!successfullyFormSubmitted && (
        <section>
          <h3>Write Down Your Query</h3>
          <BoLoader loading={boloader} />
          <Form onSubmit={submitHelpHandler} ref={form}>
            <div className="row mt-20">
              <div className="col-lg-4 col-md-6">
                <div className="form-group mb-16" id="helpTitle_frmGrp">
                  <label>Subject</label>
                  <div className="form-controls">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={helpTitle}
                      name="helptitle"
                      onChange={helpTitleChangeHandler}
                      validations={[TitleRequired]}
                    />
                    <span className="icon-edit _ico"></span>
                  </div>
                </div>
              </div>
              <div className="col-12"></div>
              <div className="col-lg-4 col-md-6">
                <div className="form-group" id="helpDescp_frmGrp">
                  <label>Description</label>
                  <div className="form-controls">
                    <TextArea
                      className="form-control"
                      rows="6"
                      placeholder="Write your message here"
                      value={helpDescription}
                      name="helptitle"
                      onChange={helpDescriptionChangeHandler}
                      validations={[DescRequried]}
                    ></TextArea>
                    <span className="icon-description _ico"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="form-group submit-field d-flex justify-content-between mb-0">
                <button className="btn btn-large mr-19">Submit</button>
                <button
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    document
                      .getElementById("cardsNavLink")
                      .classList.add("active");
                    document
                      .getElementById("helpNavLink")
                      .classList.remove("active");
                    history.push("/cardholdercards");
                   
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </section>
      )}
      {successfullyFormSubmitted && (
        <section>
          <div className="icon-alert icon-alert-popup">
            <span className="icon-successful">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <h3>Request Submitted</h3>
          <div className="row mt-16">
            <div className="col-md-4 col-9 max-350">
              <p>
                For further queries Admin will reach out via email if more
                details are required we will contact you.
              </p>
            </div>
          </div>
          <div className="form-group submit-field mt-24 mb-0">
            <button
              className="btn btn-large"
              onClick={() => {setsuccessfullyFormSubmitted(false);sethelpTitle("");sethelpDescription("");}}
            >
              Back to Help
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default Help;
