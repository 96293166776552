import React, { useState, useRef, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import ModelPopup from "../../helpers/ModelPopup";
import cardsService from "../../services/cards.services";
import { useSelector } from "react-redux";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import RefreshToken from "../../helpers/RefreshToken";
var fontStyle = {
    textTransform: "uppercase",
};
var headerData = ["VIEW","TRANS AMOUNT",
    "MERCHANT",
    "EXPIRY",
    "ERRORS",
    "STATUS"
    ]
const DSecureCode = (props) => {
    const { user: currentUser } = useSelector(state => state.auth);
    const [loadingData, setLoadingData] = useState(false);
    const [searchData, setSearchData] = useState();
    const [userID, setUserId] = useState(currentUser?.user?.UserId);
    const [searchGridSuccess, setSearchGridSuccess] = useState(false);
    const [modalShowTransactions, setModalShowTransactions] = useState(false);
    const searchTbl = useRef();
    const [totalCount, setTotalCount] = useState(0);
    const [ApprovalStatus, setApprovalStatus] = useState(0);
    const [RequestId, setRequestId] = useState();
    const [confirmmodalShow, setCnfrmModalShow] = useState(false);
    const [message, setmessage] = useState(false);
    //Dialouge Popup Error Messages
    const [modalShowDialouge, setModalShowDialouge] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    //Success Message
    const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
    const [boloader, setBoLoader] = useState(false);
    const errorAndSuccessModl = (heading, body, Icon) => {
        setModalShowDialouge(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(Icon);
    };
    const showTransactionsPopup = (
        card3DSecureID,
    ) => {
        setRequestId(card3DSecureID);
        setCnfrmModalShow(true);

    };
    useEffect(() => {
        refereshCode();
    }, [])
    const refereshCode = () => {
        setSearchGridSuccess(false);
        setBoLoader(true);
        cardsService.get3drequest(currentUser?.user?.UserId).then((resp) => {
            if (resp?.data?.payload?.item2) {
                let data = resp?.data?.payload?.item1;
                if (data)
                    setSearchData(data);
                else
                    setSearchData("");
                setBoLoader(false);
                setSearchGridSuccess(true);


            }

        }).catch((error) => {
            setSearchGridSuccess(false);
            setBoLoader(false);
            let msgBody = error?.response?.data?.message
            setmessage(msgBody);

        })
    }
    const acceptCode = (isApproved) => {
        setBoLoader(true);
        setCnfrmModalShow(false);
        cardsService
            .dRequestapproval(userID, isApproved, RequestId).then((response) => {
                setBoLoader(false);
                if (response)
                    setModalsuccessfullShow(true);
            }).catch((error) => {
                setBoLoader(false);
                let msgBody = error?.response?.data?.message
                    ? error?.response?.data?.message
                    : "Error Occured";
                let errIcon = "icon-error";
                errorAndSuccessModl("Error", msgBody, errIcon);
                (async () => {
                    let resp = await RefreshToken(
                        error && error.response && error.response.status
                    );
                    if (resp) {
                        if (resp) {
                            acceptCode();
                            setBoLoader(false);
                        } else {
                            setBoLoader(false);
                        }
                    }
                })();
            });
    }
    const renderTableHeader = () => {
        let header = Object.values(headerData);
        return header.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>;
        });
    };

    const renderTableData = () => {
        return (
            <tr key={searchData?.card3DSecureID}>
                <td>
                    <span
                        className="icon-show"
                        onClick={() =>
                            showTransactionsPopup(
                                searchData?.card3DSecureID

                            )
                        }
                    ></span>
                </td>
                <td style={fontStyle}>{searchData?.transAmount}</td>
                <td style={fontStyle} title={searchData?.merchant} >{searchData?.merchant?.substr(0, 10)}{searchData?.merchant?.length > 10 && ("...")}</td>
                <td style={fontStyle} title={searchData?.expiry} >{searchData?.expiry?.substr(0, 10)}{searchData?.expiry?.length > 10 && ("...")}</td>
                <td style={fontStyle} title={searchData?.errors} >{searchData?.errors?.substr(0, 10)}{searchData?.errors?.length > 10 && ("...")}</td>
                <td style={fontStyle} title={searchData?.card3DSecureStatus} >{searchData?.card3DSecureStatus?.substr(0, 10)}{searchData?.card3DSecureStatus?.length > 10 && ("...")}</td>
                
            </tr>
        );

    };
    return (

        <Fragment>
            <BoLoader loading={boloader} />
            <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
                <div className="row align-items-center mt-24">
                    <div className="col-6">
                        <h3> 3DS Secure Codes</h3>
                    </div>
                    <div className="col-6">
                        <button
                            className="btn btn-large btn-extra-large mb-3 mb-md-0 float-end res-custom-btn"
                            onClick={refereshCode}
                        >
                            Referesh
                        </button>
                    </div>


                </div>
                <div className="">
                    <div className="tab-content" id="myTabContent2">
                        <div
                            className="tab-pane fade show active"
                            id="allClients"
                            role="tabpanel"
                            aria-labelledby="allClients-tab"
                        >
                            {/* no client found  */}
                            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
                                <div className="text-center">
                                    <span className="icon-clients-empty">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                    </span>
                                    <h3>Empty Codes Listing</h3>
                                    <h6>There is no Code(s) available for now.</h6>
                                </div>
                            </div>
                        </div>

                        {searchGridSuccess && (
                            <div className="table-wrap" ref={searchTbl}>
                                <table
                                    className="table table-borderless bo-table table-cards"
                                //onScroll={tblScrollHandler}
                                //onWheel={tblScrollHandler}
                                >
                                    <thead>
                                        <tr>{renderTableHeader()}</tr>
                                    </thead>
                                    <tbody>{renderTableData()}</tbody>
                                </table>
                            </div>
                        )}
                        {!searchGridSuccess && (
                            <div className="d-flex align-content-center justify-content-center no-clients-msg ">
                                <div className="text-center">
                                    <h3>Empty 3DS Secure Code Listing</h3>
                                    <h6>{message}.</h6>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <div>
                <DialoguePopup
                    show={modalShowDialouge}
                    onHide={() => setModalShowDialouge(false)}
                    modelheading={modelhdng}
                    modelbody={modelbdy}
                    modelicon={MdlIcon}

                />
                <ModelPopup
                    key={1}
                    show={confirmmodalShow}
                    onHide={() => setCnfrmModalShow(false)}
                    modelheading={""}
                    showheader={"false"}
                    className="smallPopup issueCardSmall"
                    dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                    backdrop="static"
                    keyboard={false}
                    modelbody={[
                        <Fragment>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="icon-alert icon-alert-popup">
                                        <span className="icon-successful">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </span>
                                    </div>
                                    <h2>3DS</h2>
                                    <div className="mt-16">
                                        <div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>Please Select</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline mr-19"

                                                                onClick={() => {
                                                                    setCnfrmModalShow(false);
                                                                    acceptCode("1");
                                                                }}
                                                            >
                                                                Approve
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-large"
                                                                onClick={() => {
                                                                    setCnfrmModalShow(false);
                                                                    acceptCode("1");
                                                                }}
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>,
                    ]}
                />
                <ModelPopup
                    key={1}
                    show={modalsuccessfullShow}
                    onHide={() => setModalsuccessfullShow(false)}
                    modelheading={""}
                    showheader={"false"}
                    className="smallPopup issueCardSmall"
                    dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                    backdrop="static"
                    keyboard={false}
                    modelbody={[
                        <Fragment>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="icon-alert icon-alert-popup">
                                        <span className="icon-successful">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </span>
                                    </div>
                                    <h2>Successful</h2>
                                    <div className="mt-16">
                                        <div>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>3DS Code Accepted Successfully</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field mt-40 mb-0">
                                                            <Link
                                                                to="#"
                                                                className="btn btn-large w-100"
                                                                onClick={() => {
                                                                    setModalsuccessfullShow(false);
                                                                    refereshCode();
                                                                }}
                                                            >
                                                                Done
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>,
                    ]}
                />
            </div>


        </Fragment>);
}

export default DSecureCode;