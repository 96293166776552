import React, { useEffect, Suspense, lazy, Fragment, useState } from "react";
require("../../src/assets/scss/screen1.css");
const selectedTheme = localStorage.getItem("ThemeSelected");
if (selectedTheme === "theme1") {
  import("../../src/assets/scss/screen1.css");
} else {
  localStorage.setItem("ThemeReload", "true");
  import("../../src/assets/scss/screen1.css");
}

const Theme7 = lazy(async () => {
  let obj = await import("../../src/assets/scss/screen1.css");
  return typeof obj.default === "function" ? obj : obj.default;
});

const ThemeSelector = ({ children }) => {
  var CHOSEN_THEME = "selectedTheme";
  return (
    <Fragment>
      <React.Suspense fallback={<div></div>}>
        {CHOSEN_THEME === "theme7" && <Theme7 />}
      </React.Suspense>
      {children}
    </Fragment>
  );
};

export default ThemeSelector;
