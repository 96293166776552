import { Fragment } from "react";
import { useSelector } from "react-redux";
import kapedicon from '../../assets/images/kapedicon.png';


const KapedVirtualAssistant = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    return (
        <Fragment>
            <div>
                
            </div>
            {/* {currentUser?.user?.ClientId ===35 ? (<div id="chat-circle" class="btn-raised">
                <div id="chat-overlay"></div>
                <a href="https://partner.kaped.io/assets/images/email/billpay.html" target="_blank">
                <img class="chat-circle_robot" src={kapedicon}/>
                </a>
            </div>) : ("")} */}



        </Fragment>
    );


}
export default KapedVirtualAssistant;
