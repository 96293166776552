import React, { useState, useRef, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import validator, { isEmail } from "validator";
import { register } from "../../actions/account";
import accountService from "../../services/account.service";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";
import logo from "../../assets/images/logo.svg";
import moment from "moment";

const passrequired = (value) => {
  if (!value) {
    document.getElementById("chSgnUpPass_FrmGrp").classList.add("has_error");
    return <span className="help-block">Password is required!</span>;
  } else
    document.getElementById("chSgnUpPass_FrmGrp").classList.remove("has_error");
};
const required = (value) => {
  if (!value) {
    return <span className="help-block">this field is required!</span>;
  }
};
const confrmPassRequired = (value) => {
  if (!value) {
    document.getElementById("chSgnUpCPass_FrmGrp").classList.add("has_error");
    return <span className="help-block">Confirm Password is required!</span>;
  } else
    document
      .getElementById("chSgnUpCPass_FrmGrp")
      .classList.remove("has_error");
};
let passwordValue = "";
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email!
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 2 || value.length > 50) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 2 and 50 characters!
      </div>
    );
  }
};

const vpassword = (value) => {
  passwordValue = value;
  if (value.length < 8 || value.length > 20) {
    document.getElementById("chSgnUpPass_FrmGrp").classList.add("has_error");
    return <span className="help-block">The password must be between 8 and 20 characters!</span>;
  } else
    document.getElementById("chSgnUpPass_FrmGrp").classList.remove("has_error");
};
const vcpassword = (value) => {
  if (value.length < 8 || value.length > 20) {
    document.getElementById("chSgnUpCPass_FrmGrp").classList.add("has_error");
    return (
      <span className="help-block">The password must be between 8 and 20 characters!</span>
    );
  } else if (value !== passwordValue) {
    document.getElementById("chSgnUpCPass_FrmGrp").classList.add("has_error");
    return (
      <span className="help-block">
        Password and confirm password does not match!
      </span>
    );
  } else
    document
      .getElementById("chSgnUpCPass_FrmGrp")
      .classList.remove("has_error");
};
const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { height, width } = useWindowDimensions();
  const [Name, setBoUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState("");
  const [Address, setAddress] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);
  const [showHidePassClass, setshowHidePassClass] = useState("icon-hidden");
  const [showHideCPassClass, setshowHideCPassClass] = useState("icon-hidden");
  const { message } = useSelector((state) => state.message);
  const [boloader, setBoLoader] = useState(false);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get("Id");
  const kycPermission = params.get("kycallow");

  const [emailmsg, setemailmsg] = useState(true);
  const [clientlogo, setclientlogo] = useState("");
  const [theme, settheme] = useState("");

  useEffect(() => {
    setBoLoader(true);
    accountService
      .getUserbyId(userId)
      .then((resp) => {
        setBoLoader(false);
        if (resp && resp.data && resp.data.success) {
          setBoUserName(resp.data.root.name);
          setEmail(resp.data.root.email);
          setPhoneNumber(resp.data.root.phoneNumber);
          setDob(moment(resp.data.root.dateOfBirth).format("MMMM D,YYYY"));
          setAddress(resp.data.root.address);
          setclientlogo(resp.data.root.logoUrl);
          settheme(resp.data.root.themeName);
          if(resp.data.root.primaryColor){
            //let themeName=resp.data.root.themeName;
            localStorage.setItem("Reload","Reload ClientSignup");
            var primary = resp.data.root.primaryColor;
            var secondary = resp.data.root.secondaryColor;
            var lighter1 = resp.data.root.colorPrimarylighter1;
            var lighter2 =resp.data.root.colorPrimarylighter2;
            document.documentElement.style.setProperty("--color-primary", primary);
            document.documentElement.style.setProperty('--color-secondary',secondary);
            document.documentElement.style.setProperty('--color-primary-lighter-1',lighter1);
            document.documentElement.style.setProperty('color-primary-lighter-2',lighter2);
            // if(themeName==="theme1"){
            //   localStorage.setItem("ThemeSelected", "theme1");
            // }
            //  if(themeName==="theme2"){
            //   localStorage.setItem("ThemeSelected", "theme2");
            // }
            // if(themeName==="theme3"){
            //   localStorage.setItem("ThemeSelected", "theme3");
            // }
            //  if(themeName==="theme4"){
            //   localStorage.setItem("ThemeSelected", "theme4");
            // }
            // if(themeName==="theme5"){
            //   localStorage.setItem("ThemeSelected", "theme5");
            // }
            //  if(themeName==="theme6"){
            //   localStorage.setItem("ThemeSelected", "theme6");
            // }
            // if(themeName==="theme7"){
            //   localStorage.setItem("ThemeSelected", "theme7");
            // }
            //  if(themeName==="theme8"){
            //   localStorage.setItem("ThemeSelected", "theme8");
            // }
            // if(themeName==="theme9"){
            //   localStorage.setItem("ThemeSelected", "theme9");
            // }
            let themestorage=localStorage.getItem("ThemeReload");
            if(themestorage==="true"){
              localStorage.setItem("ThemeReload","false");
              // window.location.reload();
          }
          }
        }
      })
      .catch(() => {
        setBoLoader(false);
      });
      setTimeout(() => {
        setemailmsg(false);
      }, 5000);
  }, []);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const showHidePassHandler = () => {
    setPasswordShown(passwordShown ? false : true);
    setshowHidePassClass(passwordShown ? "icon-hidden" : "icon-show");
  };
  const showHideCPassHandler = () => {
    setCPasswordShown(cPasswordShown ? false : true);
    setshowHideCPassClass(cPasswordShown ? "icon-hidden" : "icon-show");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };
  const onChangeDOB = (e) => {
    const DOB = e.target.value;
    setDob(DOB);
  };
  const onChangePhoneNumber = (e) => {
    const number = e.target.value;
    setPhoneNumber(number);
  };
  const onChangeAddress = (e) => {
    const address = e.target.value;
    setAddress(address);
  };
  const onChangeBoUsername = (e) => {
    const name = e.target.value;
    setBoUserName(name);
  };
  const phoneNumberKeyPress = (event) => {
    if (!/[+0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const handleRegister = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      dispatch(
        register(
          Name,
          email,
          password,
          phoneNumber,
          moment(dob).format("YYYY-MM-DD"),
          Address,
          userId
        )
      )
        .then(() => {
          setBoLoader(false);
          props.history.push(
            "/2FAVerification",
           { calledfrom:"To the Card Holder Account" , userid:userId,kycPermission:kycPermission}
          );
        })
        .catch(() => {
          setBoLoader(false);
        });
    }
  };

  return (
    <div className="row">
      <BoLoader loading={boloader} />
      {width > 767 && <BoPaymentWrapper
        pagename={["Create An", <br />, "Account"]}
        pagemsg={"To the Card Holder Account"}
        clientlogo={clientlogo}
        themename={theme}
      />}


      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div className="row">
          {width <= 767 && <div className="col-12">
            <div className="bo-thumb">
              {/* <a href="/">
                <img src={logo} className="img-fluid" alt="BO Payments" />
              </a> */}
            </div>
            <h2>Create Account</h2>
          </div>}

          <div className="bo-on-screens position-relative">
            {
              emailmsg &&
              <div className="col-sm-12 col-md-10 alert-messages alert-info alert-mbl-common">
              <span className="icon-success"></span>
              <p>The Email Verification is Successful!</p>
            </div>
            }
            
            {message && (
              <Fragment>
                {!message.data.success && (
                  <div class="col-sm-12 col-md-10 alert-messages alert-info alert-mbl-common">
                    <span class="icon-info"></span>
                    <p>
                      {message.data.message.includes(",") ? (
                        <Fragment>
                          {message.data.message.split(".")[0].replace(",", "")}
                          <br />
                          {message.data.message.split(".")[1].replace(",", "")}
                          <br />
                          {message.data.message.split(".")[2].replace(",", "")}
                        </Fragment>
                      ) : (
                        message.data.message
                      )}
                    </p>
                  </div>
                )}
              </Fragment>
            )}
            <div className="register-top-mbl row">
              <div className="col-md-8 mb-40">
                <h3>Here you can review and update your details.</h3>
              </div>
              <div className="col-sm-12 col-md-10">
                <Form onSubmit={handleRegister} ref={form}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor="cardholderName">Name</label>
                        <div className="form-controls">
                          <Input
                            type="text"
                            className="form-control"
                            name="cardholderName"
                            value={Name}
                            onChange={onChangeBoUsername}
                            validations={[required, vusername]}
                            disabled={true}
                          />
                          <span className="icon-user _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <div className="form-controls">
                          <Input
                            type="text"
                            className="form-control"
                            name="email"
                            title={email}
                            value={email}
                            onChange={onChangeEmail}
                            validations={[required, validEmail]}
                            disabled={true}
                          />
                          <span className="icon-email _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor="phonenumber">Phone Number</label>
                        <div className="form-controls">
                          <Input
                            type="text"
                            className="form-control"
                            name="phonenumber"
                            value={phoneNumber}
                            onChange={onChangePhoneNumber}
                            validations={[required]}
                            onKeyPress={phoneNumberKeyPress}
                            disabled={true}
                          />
                          <span className="icon-phone _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor="dob">Date Of Birth</label>
                        <div className="form-controls">
                          <Input
                            type="text"
                            className="form-control"
                            name="dob"
                            value={dob}
                            onChange={onChangeDOB}
                            validations={[required]}
                            disabled={true}
                          />
                          <span className="icon-calender _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <div className="form-controls">
                          <Input
                            type="text"
                            className="form-control"
                            name="address"
                            value={Address}
                            onChange={onChangeAddress}
                            // validations={[required]}
                            disabled={true}
                          />{" "}
                          <span className="icon-home _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group" id="chSgnUpPass_FrmGrp">
                        <label htmlFor="password">Password*</label>
                        <div className="form-controls">
                          <Input
                            type={passwordShown ? "text" : "password"}
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[passrequired, vpassword]}
                            maxLength="20"
                          />
                          <span className="icon-lock _ico"></span>
                          <span
                            className={"show-password " + showHidePassClass}
                            onClick={showHidePassHandler}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group" id="chSgnUpCPass_FrmGrp">
                        <label htmlFor="confirmPassword">Confirm Password*</label>
                        <div className="form-controls">
                          <Input
                            type={cPasswordShown ? "text" : "password"}
                            className="form-control"
                            name="confirmPassword"
                            value={confirmpassword}
                            onChange={onChangeConfirmPassword}
                            validations={[confrmPassRequired, vcpassword]}
                            maxLength="20"
                          />
                          <span className="icon-lock _ico"></span>
                          <span
                            className={"show-password " + showHideCPassClass}
                            onClick={showHideCPassHandler}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                      <div className="form-group submit-field">
                        <button className="btn btn-large w-100">Sign Up</button>
                      </div>
                    </div>
                  </div>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
