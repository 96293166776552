
let CardToCard=false;
let DebitCard=false;
let UserKyc=false;
export const haspermissions={
    CardToCard,
    DebitCard,
    UserKyc
}

