import React, { useEffect, useState } from "react";
import SettingsPageData from "../SettingsPageData";
import SettingIdVerification from "./SettingIdVerification";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";

const Settings = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const [ProfileTab, setProfileTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [ProfileTabAreaSelected, setProfileTabAreaSelected] = useState("false");
  const [IdVerificationTab, setIdVerificationTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [IdVerificationTabAreaSelected, setIdVerificationTabAreaSelected] =
    useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  if (currentUser) {
    haspermissions.CardToCard= currentUser?.user?.Permissions?.includes("Card to Card Transfer");
    haspermissions.UserKyc= currentUser?.user?.Permissions?.includes("User KYC");
   
  }
  useEffect(() => {
    btnProfile();
  }, []);
  const btnProfile = () => {
    setProfileTab("nav-link active d-flex align-items-center");
    setProfileTabAreaSelected("true");
    setIdVerificationTab("nav-link d-flex align-items-center");
    setIdVerificationTabAreaSelected("false");

    setContent(<SettingsPageData />);
  };

  const btnIdVerification = () => {
    setIdVerificationTab("nav-link active d-flex align-items-center");
    setIdVerificationTabAreaSelected("true");
    setProfileTab("nav-link d-flex align-items-center");
    setProfileTabAreaSelected("false");
    setContent(<SettingIdVerification />);
  };

  return (
    <div className="col-xl-9 col-lg-12 bo-main-content bo-main-nopad">
      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={ProfileTab}
              id="profile-tab"
              data-bs-toggle="tab"
              onClick={btnProfile}
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected={ProfileTabAreaSelected}
            >
              <span className="icon-client"></span>
              Profile info
            </button>
          </li>
         { haspermissions.UserKyc &&(
          <li className="nav-item" role="presentation">
            <button
              className={IdVerificationTab}
              id="roles-tab"
              data-bs-toggle="tab"
              onClick={btnIdVerification}
              data-bs-target="#roles"
              type="button"
              role="tab"
              aria-controls="roles"
              aria-selected={IdVerificationTabAreaSelected}
            >
              <span className="icon-verification"></span>ID verfication
            </button>
          </li>
         )} 
        </ul>
      </div>
      <div className="bo-main-content-sub">
        <div className="tab-content" id="myTabContent2">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Settings;
