import { Fragment, useState, useRef } from "react";
import useWindowDimensions from "../../helpers/WindowWidth";
import CardDetails from "./CardDetails";
import KapedVirtualAssistant from "./KapedVirtualAssistant";
import P2PTransfer from "./P2PTransfer";

const SendMoney = () => {
    const { height, width } = useWindowDimensions();

  return (
    <Fragment>
       
     <P2PTransfer/>
    
        
      {width > 991 && (
        <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
          <div className="bo-right-sidebar-wrap">
          <KapedVirtualAssistant/>
            <div className="mb-20">
              <h3>
                Your Cards<span className="bo-badge bo-badge-large">1</span>
              </h3>
            </div>
            <CardDetails cht="Y" />
          </div>
        </div>
      )}
       
    </Fragment>
  );
};

export default SendMoney;
