import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { useExternalScript } from "../KapedCardNew/useExternalScript";

const Navigation = props => {
    const externalScript = "https://admin.kaped.io/pay-bill-widget/script.js";
    const state = useExternalScript(externalScript,props?.clientId);

  const [activeClass, setActiveClass] = useState();
  const applyActiveClass = id => {
    setActiveClass(id);
  };
  useEffect(() => {
    setTimeout(() => {
      setActiveClass(window.location.pathname.replace("/", ""));
    }, 200);
  }, []);
  
  const closeLeftMenu = () => {
    if (document.getElementById("boMenu").classList.contains("bo-menu-overlay")) {
      document.getElementById("boMenu").classList.remove("bo-menu-overlay");
      document.getElementById("body").style = "overflow: visible";
      document.getElementById("leftNav").style.cssText =
        "position: unset; width: 0; height: 0; z-index: ; display : none";
    }
  }
 
// const buttonB=()=>{
//   document.getElementById("start-funding").click();
// }
  var setpermission = localStorage.getItem("activeInactiveSendmoney");
  return (
    <div className="col-lg-3 col-md-0 bo-sidebar-col" id="leftNav">
      <div className="bo-sidebar-outer">
        <Link
          to="/dashboard"
          className={
            activeClass === "dashboard" || activeClass === "" ? "active" : ""
          }
          onClick={() => { applyActiveClass("dashboard"); closeLeftMenu(); }}
        >
          <div className="logo d-flex align-items-center bo-thumb">
            {props.logo != "" ? (
              <img width="100" src={props.logo} className="img-fluid" alt="BO Payments" />
            ) : (
              <Link to="#">
                <h3>
                  Card<span>Console</span>
                </h3>
              </Link>
            )}
          </div>
        </Link>
        <ul className="sidebar-ul">
          {(props?.userkyc && props?.iskycsubmitted &&(props?.KycApprovedByIssuer===null ||props?.KycApprovedByIssuer==="No") ) ? (
            <Fragment>
              {props.role.includes("Card holder") && (
                <Fragment>
                  <li>
              <Link
                to={"/identityVerification"}
                id="helpNavLink"
                className={activeClass === "identityVerification" ? "active" : ""}
                onClick={() => { applyActiveClass("identityVerification"); closeLeftMenu(); }}
              >
                <i className="icon-client"></i>Kyc
              </Link>
            </li>
              

                </Fragment>
              )}
            </Fragment>
          ) : (!props?.iskycsubmitted && props?.userkyc)? (
            <li>
              <Link
                to={"/identityVerification"}
                id="helpNavLink"
                className={activeClass === "identityVerification" ? "active" : ""}
                onClick={() => { applyActiveClass("identityVerification"); closeLeftMenu(); }}
              >
                <i className="icon-client"></i>Kyc
              </Link>
            </li>
          ) :(
            <Fragment>
            {props.role.includes("Card holder") && (
              <Fragment>
                <li>
                  <Link
                    id="dashboardNavLink"
                    to={"/dashboard"}
                    className={activeClass === "dashboard" ? "active" : ""}
                    onClick={() => { applyActiveClass("dashboard"); closeLeftMenu(); }}
                  >
                    <i className="icon-dashboard"></i>Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cardholdercards"}
                    id="cardsNavLink"
                    className={activeClass === "cards" ? "active" : ""}
                    onClick={() => { applyActiveClass("cards"); closeLeftMenu(); }}
                  >
                    <i className="icon-card"></i>My Cards
                  </Link>
                </li>
                {
                  (props?.IssuerName==="IssuerB4B" || props?.IssuerName==="IssuerB4Virtual") &&(
                    <li>
                   <Link
                     to={"/3dcode"}
                     id="cardsNavLink"
                     className={activeClass === "3dcode" ? "active" : ""}
                     onClick={() => { applyActiveClass("3dcode"); closeLeftMenu(); }}
                   >
                     <i className="icon-accepted"></i>3DS
                   </Link>
                 </li>

                  )
                   

                }
               

                {
                  props.sendmoneypermission && (
                    <Fragment>
                      {setpermission !== "true" && (
                        <li>
                          <Link
                            id="moneyNavLink"
                            to={setpermission !== "true" ? "/sendmoney" : "/"}
                            //to= {"/sendmoney"}
                            className={activeClass === "money" ? "active" : ""}
                            onClick={() => { applyActiveClass("money"); closeLeftMenu(); }}
                          >
                            <i className="icon-Wallet"></i>Send Money
                            {/* <span className="bo-badge">2</span> */}
                          </Link>
                        </li>
                      )}
                    </Fragment>
                  )
                }

                  <li>
                    <Link
                      id="transactionNavLink"
                      to={"/cardholdertransactions"}
                      className={
                        activeClass === "cardholdertransactions" ? "active" : ""
                      }
                      onClick={() =>{ applyActiveClass("cardholdertransactions");closeLeftMenu();}}
                    >
                      <i className="icon-transaction"></i>Transactions
                      {/* <span className="bo-badge">2</span> */}
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/cardHolder/settings"}
                      className={
                        activeClass === "cardHolder/settings" ? "active" : ""
                      }
                      onClick={() => {applyActiveClass("cardHolder/settings");closeLeftMenu();}}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/help"}
                      id="helpNavLink"
                      className={activeClass === "help" ? "active" : ""}
                      onClick={() => {applyActiveClass("help");closeLeftMenu();}}
                    >
                      <i className="icon-help"></i>Help
                    </Link>
                  </li>
                 {/* {
                   (props?.clientId===35) &&(
                    <li>
                    <Link
                      to={"/dashboard"}
                      id="helpNavCapital"
                      className={activeClass === "Capital" ? "active" : ""}
                      onClick={() => {applyActiveClass("Capital");buttonB();}}
                    >
                      <i className="icon-deposit"></i>Capital
                    </Link>
                  </li>
                   )
                 } 
                  */}
                </Fragment>
              )}
            </Fragment>
          )}
          <hr />
          <li>
            <Link
              to={"/login"}
              className="d-flex align-items-center logout"
              onClick={() => { props.logOut(); closeLeftMenu(); }}
            >
              <i className="icon-logout"></i>
              <span>
                <span>Logout</span>
                <br />
                <span className="user-name">{props.username}</span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Navigation;
