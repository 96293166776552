import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL = Env.CardsApiUrl;
const IssueCardToCardHolder = (
  Name,
  email,
  phonenumber,
  dateofbirth,
  address
) => {
  return axios
    .post(
      API_URL + "issuecard",
      {
        Name,
        email,
        phonenumber,
        dateofbirth,
        address,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const approveRejectCardHolder = (status, cardUuid) => {
  return axios
    .post(
      API_URL + "approverejectcard?status=" + status + "&cardUuid=" + cardUuid,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const RegisterCardHolder = (
  Name,
  email,
  phoneNumber,
  DateofBirth,
  Address,
  cardExpiry,
  availableBalance,
  Rolename,
  username,
  cardId,
  ClientId
) => {
  return axios
    .post(
      API_URL + "registercardholder",
      {
        Name,
        email,
        phoneNumber,
        DateofBirth,
        Address,
        cardExpiry,
        availableBalance,
        Rolename,
        username,
        cardId,
        ClientId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const ActivatePhysicalCard = (
  CardNumber,
  KitNumber,
  UserId,
) => {
  return axios
    .post(
      API_URL + "activatecardbycardholder",
      {
        CardNumber,
        KitNumber,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getCardStatusData = (clientId) => {
  return axios
    .post(
      API_URL + "getcarddashboardstats?clientId=" + clientId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const searhGrid = (
  name,
  tokenId,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName
) => {
  return axios
    .post(API_URL + methodName, {
      name,
      tokenId,
      issueDate,
      expiryDate,
      dateFrom,
      dateTo,
      startIndex,
    },  {
      headers: authHeader(),
    })
    .then((resp) => {
      return resp;
    });
};
const get3drequest = (userId) => {
  return axios
    .post(
      API_URL + "get3drequest?userId=" + userId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const dRequestapproval = (
  UserId,
  ApprovalStatus,
  RequestId,
 
) => {
  return axios
    .post(
      API_URL + "3drequestapproval",
      {
        UserId,
        ApprovalStatus,
        RequestId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const cardsService = {
  IssueCardToCardHolder,
  RegisterCardHolder,
  getCardStatusData,
  searhGrid,
  approveRejectCardHolder,
  ActivatePhysicalCard,
  get3drequest,
  dRequestapproval
};
export default cardsService;
