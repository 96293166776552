 import React, { useState,useEffect } from "react";
import { Fragment } from "react";
import CardDetails from "./CardDetails";
import CardHolderDetails from "./CardHolderDetails";
import SearchGridCards from "./SearchGridCards";
import CardLimit from "./CardLimits";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import useWindowDimensions from "../../helpers/WindowWidth";
import { useSelector } from "react-redux";
import SearchGridCardTransactions from "./SearchGridCardTransactions";
const CardHolderCards = () => {
  // const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const { height, width } = useWindowDimensions();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(currentUser.user.UserId);
  const [ClientBalance, setClientBalance] = useState();
  const [CardNumber, setCardNumber] = useState();
  const [creditBalance, setCreditBalance] = useState("");
  const [pendingbalance, setpendingbalance] = useState("");
  const getCardDetails = () => {
    cardholdercardsObj.getCardDetails().then(
      (response) => {},
      (error) => {}
    );
  };
  const getCardLimits = () => {
    cardholdercardsObj.getCardLimits().then(
      (response) => {},
      (error) => {}
    );
  };
  useEffect(() => {
    cardholdercardsObj.getCardNumber(UserId).then(
      (response) => {
        setCardNumber(response.data.payload.result.tokenId);
      },
      (error) => {
      }
    );
   
  }, [])
  useEffect(() => {
    if (currentUser?.user?.IsPhysicalCard) {
      cardholdercardsObj.getBalancePhysical(UserId).then(
        (response) => {
          if(response && response.data && response.data.payload){
          setClientBalance(response.data.payload.result.item1.balance);
          setpendingbalance(response.data.payload.result.item1.pendingBalance);
          }
        },
        (error) => {
        
        }
      );
    }
    else  {
      
    cardholdercardsObj.getSuperAdminBalance(UserId).then(
      (response) => {
        if(response && response.data && response.data.payload)
        setClientBalance(response.data.payload.result.item1.balance);
        
      },
      (error) => {
      
      }
    );
    }
  }, [])
  useEffect(() => {
    cardholdercardsObj.creditLimitCalculation(UserId).then((resp)=>{
      setCreditBalance(resp?.data?.payload?.result?.totalLimit);
    }).catch((error)=>{

    });

  }, [])

  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <h3>
          Your Cards<span className="bo-badge bo-badge-large">1</span>
        </h3>
        <div className="row mt-20">
          <CardDetails cardnumber={CardNumber} ClientBalance={ClientBalance} cht="N"/>
          <CardLimit />
        </div>

        <div className="row">{width <= 991 && <CardHolderDetails cardnumber={CardNumber} ClientBalance={ClientBalance} creditBalance={creditBalance} pendingbalance={pendingbalance}/>}</div>
        <SearchGridCardTransactions
          placeholdervalue="Search Card Holder Transactions"
          headersdata={[
            "DATE",
            "MERCHANT NAME",
            "STATUS",
            // "CURRENCY",
            "AMOUNT",
            // "REF #",
            "VIEW",
          ]}
          screenname="cardholdertransactions"
        />
      </div>
      
      {width > 991 && <CardHolderDetails cardnumber={CardNumber} ClientBalance={ClientBalance} creditBalance={creditBalance} pendingbalance={pendingbalance}/>}
    </Fragment>
  );
};

export default CardHolderCards;
