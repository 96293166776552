import "../KapedCardNew/style.css"
import { gsap } from "gsap";



const KapedNewCard = (props) => {
   
    let t1 = gsap.timeline({ paused: true, reversed: true });
let watch = false;
const card = document.querySelector(".container-kaped");
let t2 = gsap.timeline({ paused: true, reversed: true });
let watch2 = false;
const card2 = document.querySelector(".container-kaped");

card2?.addEventListener("click", (e) => {
    if (!watch2) {
        t2.play();
        watch2 = true;
    } else {
        t2.reverse();
        watch2 = false;
    }
});
card?.addEventListener("click", (e) => {
  if (!watch) {
    t1.play();
    watch = true;
  } else {
    t1.reverse();
    watch = false;
  }
});
t1.to(
    ".container-kaped",
    {
        ease: "power1.inOut",
        height: 280,
    },
    0
);

t1.to(
    ".card-balance",
    {
        ease: "power1.inOut",
        y: 80,
        x: 20,
        scale: 1.1,
    },
    0.5
);

t1.to(
    ".card-name",
    {
        ease: "power1.inOut",
        y: 80,
    },
    0.5
);

t1.to(
    ".logos",
    {
        ease: "power1.inOut",
        left: 30,
        top: 45,
    },
    0.5
);

t1.to(
    ".sim",
    {
        ease: "power1.inOut",
        bottom: 40,
        opacity: 0.5,
    },
    0.5
);

t1.to(
    ".card-number",
    {
        ease: "power1.inOut",
        right: 40,
        opacity: 0.5,
    },
    0.5
);

t1.to(
    ".due-date",
    {
        ease: "power1.inOut",
        right: 40,
        opacity: 0.5,
    },
    0.5
);

t1.to(
    ".card-logo",
    {
        ease: "power1.inOut",
        right: -150,
    },
    0.6
);

t2.to(
    ".container-kaped",
    {
        ease: "power1.inOut",
        height: 280,
    },
    0
);

t2.to(
    ".card-balance",
    {
        ease: "power1.inOut",
        y: 80,
        x: 20,
        scale: 1.1,
    },
    0.5
);

t2.to(
    ".card-name",
    {
        ease: "power1.inOut",
        y: 80,
    },
    0.5
);
t2.to(
    ".logos",
    {
        ease: "power1.inOut",
        left: 30,
        top: 45,
    },
    0.5
);
t2.to(
    ".sim",
    {
        ease: "power1.inOut",
        bottom: 40,
        opacity: 0.5,
    },
    0.5
);
t2.to(
    ".card-number",
    {
        ease: "power1.inOut",
        right: 40,
        opacity: 0.5,
    },
    0.5
);
t2.to(
    ".due-date",
    {
        ease: "power1.inOut",
        right: 40,
        opacity: 0.5,
    },
    0.5
);

t2.to(
    ".card-logos",
    {
        ease: "power1.inOut",
        right: -150,
    },
    0.6
);
    return (
<div className="row">
<div class="container-kaped" >

<p class="card-name">Titanium Elite</p>
<h1 class="card-balance">$ {props?.ClientBalance}</h1>
<img class="logos" src="https://www.cosgroveny.com/wp-content/uploads/logos/visa-white-shadow.png" alt="" />

<img class="sim" src="https://i.imgur.com/noUFVNw.png" alt="" />

<p class="card-number">{props?.cardNumber}</p>
<p class="due-date">{props?.cardExpiry}</p>

<img src="https://i.imgur.com/N7ys3v2.png" alt="" class="card-logos" />
</div>
</div>
       );
}

export default KapedNewCard;