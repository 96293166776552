import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.TransactionApiUrl;
const getTransactionStatsData = (clientId) => {
  return axios
    .get(API_URL + "getTransactionstats?clientId=" + clientId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getVerificationPayee = (
  TokenId,
  Amount,
  CurrencyCode,
  UserId
) => {
  return axios
    .post(
      API_URL + "GetAccountVarification",
      {
        TokenId,
        Amount,
        CurrencyCode,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const saveP2PTransfer = (
  TokenId,
  Amount,
  CurrencyCode,
  UserId,
  Name,
  Email,
  SenderCustomerHashId,
  SenderWalletHashId,
  ReceiverWalletHashId,
  ReceiverCardHashId,
  SenderCardHashId,
  CardType
) => {
  return axios
    .post(
      API_URL + "P2PTransfer",
      {
        TokenId,
        Amount,
        CurrencyCode,
        UserId,
        Name,
        Email,
        SenderCustomerHashId,
        SenderWalletHashId,
        ReceiverWalletHashId,
        ReceiverCardHashId,
        SenderCardHashId,
        CardType
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const addToFavourite = (
  TokenId,
  Amount,
  CurrencyCode,
  UserId,
  Name,
  Email,
  SenderCustomerHashId,
  SenderWalletHashId,
  ReceiverWalletHashId
) => {
  return axios
    .post(
      API_URL + "AddFavourite",
      {
        TokenId,
        Amount,
        CurrencyCode,
        UserId,
        Name,
        Email,
        SenderCustomerHashId,
        SenderWalletHashId,
        ReceiverWalletHashId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getFavouritePayees = (userId) => {
  return axios
    .post(API_URL + "GetFavouritePayee?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const searhGridSendMoney = (name, UserId, dateFrom, dateTo, startIndex) => {
  return axios
    .post(
      API_URL + "getp2ptransactions?userId=" + UserId,
      {
        name,
        UserId,
        dateFrom,
        dateTo,
        startIndex,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridTransactionsCards = (
  UserId,
  StartDate,
  EndDate,
  // Status,
  PageNumber,
  // Size,
  // Order
) => {
  return axios
    .post(
      API_URL + "GetCardTransactions ",
      {
        UserId,
        StartDate,
        EndDate,
        // Status,
        PageNumber,
        // Size,
        // Order
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const GetCardTransactionsspecificmonth = (
  UserId,
  Month,
  Year
) => {
  return axios
    .post(
      API_URL + "GetCardTransactionsspecificmonth ",
      {
      UserId,
       Month,
       Year
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const transactionObj = {
  getTransactionStatsData,
  getVerificationPayee,
  saveP2PTransfer,
  addToFavourite,
  getFavouritePayees,
  searhGridSendMoney,
  searhGridTransactionsCards,
  GetCardTransactionsspecificmonth
};
export default transactionObj;
