import { Fragment } from "react"
import { Link } from "react-router-dom";
const Help=()=>{
    return(<Fragment>
        <div className="mt-24 bo-help">
          <h3>Help</h3>
          <p>
            For further querires Admin will reach out via email if more details
            are required we will contact you.
          </p>
          <div className="a-arrow-btn mt-20">
            <Link to="/help"
              onClick={() => {
                document
                  .getElementById("helpNavLink")
                  .classList.add("active");
                document
                  .getElementById("cardsNavLink")
                  .classList.remove("active");
              }}>
              Submit Your Query <span className="icon-btn_arrow_right"></span>
              {/* <img src={arrowright} alt="arrow" /> */}

            </Link>
          </div>
        </div>
    </Fragment>)
}
export default Help;