import { Fragment, useState, useRef, useEffect } from "react";
import addImg from "../../assets/images/icons/add.svg";
import { Link } from "react-router-dom";
import P2PTransfer from "./P2PTransfer";
import transactionService from "../../services/transaction.service";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import { useSelector } from "react-redux";
import KapedVirtualAssistant from "./KapedVirtualAssistant";
import CardHolderDetails from "./CardHolderDetails";
import cardholdercardsObj from "../../services/cardholder-cards.service";
const CHDSendMoney = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [FavouritesList, setFavouritesList] = useState([]);
  const [UserId, setUserId] = useState(String(currentUser.user.UserId));
  const [CardNumber, setCardNumber] = useState();
  const [refreshpage, setRefreshpage] = useState();
  const [ClientBalance, setClientBalance] = useState();
  const [creditBalance, setCreditBalance] = useState("");
  const [pendingbalance, setpendingbalance] = useState("");
  useEffect(() => {
    cardholdercardsObj.getCardNumber(UserId).then(
      (response) => {
        setCardNumber(response.data.payload.result.tokenId);
      },
      (error) => {
      }
    );
   
  }, [])
  useEffect(() => {
    if (currentUser?.user?.IsPhysicalCard) {
      cardholdercardsObj.getBalancePhysical(UserId).then(
        (response) => {
          if(response && response.data && response.data.payload){
          setClientBalance(response.data.payload.result.item1.balance);
          setpendingbalance(response.data.payload.result.item1.pendingBalance);
          }
        },
        (error) => {
        
        }
      );
    }
    else  {
      
    cardholdercardsObj.getSuperAdminBalance(UserId).then(
      (response) => {
        if(response && response.data && response.data.payload)
        setClientBalance(response.data.payload.result.item1.balance);
        
      },
      (error) => {
      
      }
    );
    }
  }, [])
  useEffect(() => {
    cardholdercardsObj.creditLimitCalculation(UserId).then((resp)=>{
      setCreditBalance(resp?.data?.payload?.result?.totalLimit);
    }).catch((error)=>{

    });

  }, [])
  useEffect(() => {
    transactionService
      .getFavouritePayees(UserId)
      .then((response) => {
        if (response && response.data && response.data.payload) {
          const lst_favourites = response.data.payload.result.map((data) => {
            return {
              TokenId: data.tokenId,
              Name: data.name,
              Currency: data.currencyCode,
            };
          });
          setFavouritesList(lst_favourites);
        }
      })
      .catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  }, []);
  const addToSendMoney = (e) => {
    let value = e.currentTarget.children[1].innerText;
    setCardNumber(value);
    if (refreshpage)
      setRefreshpage(false);
    else
      setRefreshpage(true);
  }
  const divStyle = ()=>{
    if(FavouritesList?.length>0)
    {return{
    height:"150px",
    overflow:"auto"}}
    else return {
      height:"0px",
    }
};
return (
  <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
    <div className="bo-right-sidebar-wrap">
      
    <CardHolderDetails cardnumber={CardNumber} ClientBalance={ClientBalance} creditBalance={creditBalance} pendingbalance={pendingbalance} calledfrom="dashboard"/>
   {
    props?.isPermission &&(
      <>
      <KapedVirtualAssistant/>
      <div className="row align-items-center">
        <div className="col-7">
          {/* <h3>Send Money</h3> */}
        </div>
        <div className="col-5">
          <div className="a-arrow-btn toRight ml--6">
            {/* <Link to="#">
                View all <span className="icon-btn_arrow_right"></span>
                {/* <img src={arrowRghtImg} alt="ar" /> */}
            {/* </Link> */}
          </div>
        </div>
      </div>

      <div className="row align-items-center favorites mt-20">
        <div className="col-9">
          <p>Send Money to Favourites</p>
        </div>
        <div className="col-3">
          {/* <span className="icon-add">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span> */}
        </div>
      </div>
      <ul className="ul-card-details-popup mt-16 ul-dashboard mb-5" style={divStyle()}>
        {FavouritesList.map((FavouritesList) => {
            return (
              <li className="d-flex justify-content-between mb-20 li-send-money" onClick={addToSendMoney}>
                <div className="d-flex align-items-center">
                  <span className="td-logo" >{FavouritesList.Name.charAt(0)}</span>
                  <b>{FavouritesList.Name}</b>
                </div>
                <span>{FavouritesList.TokenId}</span>
              </li>
            );
        })}
      </ul>
      <hr />
      {refreshpage && <P2PTransfer classparam="mt-32" cardnumber={CardNumber} calledfrom="dashboard" FavouritesList={FavouritesList} />}
      {!refreshpage && <P2PTransfer classparam="mt-32" cardnumber={CardNumber} calledfrom="dashboard" FavouritesList={FavouritesList} />}
      </>
    )
   }
      
     
    </div>
   


  </div>
);
};

export default CHDSendMoney;
