import "../AmberCard/style.css";
import { useSelector} from "react-redux";
import { Fragment, useEffect,useState } from "react";
import kapedLogo from "../AmberCard/amberlogo.png";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import eyeOff from "../../assets/images/eye-off.svg";
import eyeOn from "../../assets/images/eye-on.svg";
import CurrencyFormat from 'react-currency-format';
const AmberCard = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [UserId, setUserId] = useState(currentUser.user.UserId);
    const [Cvv, setCvv] = useState();
    const [IconShown, setIconShown] = useState(true);
    useEffect(() => {
        getCardCvv();
       // checkClientCurrency();
      }, [])
   
    //   const checkClientCurrency=()=>{
    //     if(currentUser?.user?.ClientCurrency==="USD")
    //     setClientCurrency("USD")
    //     else if(currentUser?.user?.ClientCurrency==="EUR")
    //     setClientCurrency("EUR")
    //     else if(currentUser?.user?.ClientCurrency==="GBP")
    //     setClientCurrency("GBP")
    //     else if(currentUser?.user?.ClientCurrency==="CAD")
    //     setClientCurrency("CAD")
    //    else 
    //     setClientCurrency("")
    //   }
    const getCardCvv=()=>{
        cardholdercardsObj.getCardCvv(UserId).then(
          (response) => {
            if(response && response.data && response.data.payload)
            setCvv(response.data.payload.result);
          },
          (error) => {
          }
        );
      }
      const showHideIcon = () => {
        setIconShown(IconShown ? false : true);
    
      };
    return (
        <Fragment>
            <div class="container">
        <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 mb-20">
                <div class="">
                    <div class="bo-credit-cards">
                        <div class="bo-credit-card-inner">
                            <div class="d-flex align-items-center top-bar">
                                <img class="" src={kapedLogo} />
                            </div>
                            <div class="account-balance mb-20">
                            <h1><CurrencyFormat value={currentUser?.user?.CardFlow === "CREDIT" ?
                     (props?.creditBalance - props?.ClientBalance) > 0 ? props?.creditBalance - props?.ClientBalance : 0 : props?.ClientBalance}
                     displayType={'text'} thousandSeparator={true} />&nbsp;{props?.clientCurrency}</h1>
                            </div>
                            <div class="d-flex mb-2">
                                <div class="card-no d-flex">
                                {
                       IconShown && (
                         <span>{props?.MaskedNumber}</span>
                       )
                     }
                     {
                       !IconShown && (
                         <span>{props?.cardNumber}</span>
                       )
                     }
                            
                                </div>
                                <div class="ml-auto" onClick={showHideIcon}>
                                <span className="d-flex align-items-center justify-content-center">
                       {!IconShown && (<img src={eyeOff} />)}
                       {IconShown && (<img src={eyeOn} />)}
                     </span>
                                </div>
                            </div>
                            <div class="d-flex">
                                {/* <div class="label-plus-value dull-label">
                                    <span>Valid Thru</span>
                                    <p>11/23</p>
                                </div> */}
                                <div class="label-plus-value dull-label">
                                    <span>Token ID</span>
                                    <p>{props?.TokenId}</p>
                                </div>
                                <div class="label-plus-value dull-label">
                                    <span>Currency</span>
                                    <p>{props?.clientCurrency}</p>
                                </div>
                                <div class="label-plus-value dull-label">
                                    <span>Status</span>
                                    <p>
                                        {/* <img src={tickRound} />  */}
                                        Active
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
        </Fragment>
    );
}
export default AmberCard;