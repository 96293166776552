import Modal from "react-bootstrap/Modal";
const ModelPopup = (props) => {
  return (
    <Modal {...props} size="md" aria-labelledby="confrmPopup" centered className="smallPopup issueCardSmall" dialogClassName="mwidth-42">
      <Modal.Header style={{ display: "none" }}>
        <Modal.Title id="confrmPopup">{props.modelheading}</Modal.Title>
      </Modal.Header>
      <Modal.Body mt-16>
        {" "}
        <div className="row">
          <div className="col-md-12">
            <div className="icon-alert icon-alert-popup">
              <span className={props.modelicon}>
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </div>

            <h2>{props.modelheading}</h2>
            <div className="mt-16">
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <p>{props.modelbody}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                        <button
                          className="btn btn-filled w-100"
                          onClick={() => {
                            props.modelicon.includes("icon-error")
                              ? props.onHide()
                              : props?.hideallpopups();
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: "none" }}></Modal.Footer>
    </Modal>
  );
};
export default ModelPopup;
