import React, { useState, Fragment, useEffect } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import ChangePinCardDetails from "./ChangePinCardDetails";
import { useSelector } from "react-redux";
import TwoFaForm from "../account/TwoFaForm";
import { Link } from "react-router-dom";
import RefreshToken from "../../helpers/RefreshToken";
import moment from "moment";
import eyeOn from "../../assets/images/btn_off.svg";
import eyeOff from "../../assets/images/btn_off_1.svg";
import CurrencyFormat from 'react-currency-format';
import KapedVirtualAssistant from "./KapedVirtualAssistant";
import DialoguePopup from "../../helpers/DialoguePopup";
import BoLoader from "../../helpers/BoLoader";
import Help from "./Help";
var setpermission = localStorage.getItem("activeInactiveSendmoney");
if (!setpermission)
  setpermission = "false";
const CardHolderDetails = (props) => {
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalresetshow, setModalresetshow] = useState(false);
  const [modalchangeshow, setModalchangeshow] = useState(false);
  const [calledfrom, setcalledfrom] = useState("");
  const [showTwoFA, setShowTwoFA] = useState(false);
  const [modalchangepinshow, setModalchangepinshow] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(currentUser.user.UserId);
  const [Cvv, setCvv] = useState();
  const [IconShown, setIconShown] = useState(false);
  const [IconShownPin, setIconShownPin] = useState(false);
  const [showHidePassClass, setIconHideClass] = useState("icon-hidden");
  const [ValidThru, setValidThru] = useState();
  const [isActive, setIsActive] = useState();
  const [clientCurrency, setClientCurrency] = useState("");
  const [cardpin, setCardPin] = useState("");
  const [modalrefillamountshow, setModalrefillamountshow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [dynamicClass, setDynamicClass] = useState("");
  const [dynamicClass2, setDynamicClass2] = useState("");
    //Dialogue Popup
    const [dialougeModalShow, setDialougeModalShow] = useState(false);
    const [dialougeModalShowStatus, setDialougeModalShowStatus] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");

  //Payment Processor Nium or mSwipe
  const [PaymentProcessor, setPaymentProcessor] = useState(String(currentUser?.user?.PaymentProcessor));
  const showHideModalReset = () => {
    setModalchangepinshow(false);
    setCnfrmModalShow(true);
    setShowTwoFA(true);
  };
  const showHideModalChange = () => {
    setCnfrmModalShow(false);
    setModalchangepinshow(true);
    setcalledfrom("change");
  };
  useEffect(() => {
    if (props?.calledfrom === "dashboard") {
      setDynamicClass("");
      setDynamicClass2("")
    } else {setDynamicClass("col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar");setDynamicClass2("bo-right-sidebar-wrap")}
  }, []);
  useEffect(() => {
    getCardCvv();
    checkClientCurrency();
    
  }, [])
  useEffect(() => {
    if (currentUser?.user?.IssuerName === "IssuerB4B")
    getCardPin();
  }, [])
  const getCardCvv = () => {
    cardholdercardsObj.getCardCvv(UserId).then(
      (response) => {
        if (response && response.data && response.data.payload)
          setCvv(response.data.payload.result);
      },
      (error) => {
      }
    );
  }
  const getCardPin = () => {
    cardholdercardsObj.getpinb4b(UserId).then(
      (response) => {
        if (response && response.data && response.data.payload)
          setCardPin(response.data.payload?.result?.item1);
      },
      (error) => {
      }
    );
  }
  useEffect(() => {
    getCardNumber();
  }, [])
  const getCardNumber = () => {
    cardholdercardsObj.getCardNumber(UserId).then(
      (response) => {
        setValidThru(moment(response.data.payload.result.expiryDate).format("MM/YY")
        );
      },

    ).catch((error) => {
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          getCardNumber();
        }
      })();
    });

  }
  const showHideIcon = () => {
    setIconShown(IconShown ? false : true);
    //setIconHideClass(IconShown ? "icon-hidden" : "icon-show");
  };
  const showHideIconPin = () => {
    setIconShownPin(IconShownPin ? false : true);
    
  };
  const checkClientCurrency = () => {
    if (currentUser?.user?.ClientCurrency === "USD")
      setClientCurrency("USD")
    else if (currentUser?.user?.ClientCurrency === "EUR")
      setClientCurrency("EUR")
    else if (currentUser?.user?.ClientCurrency === "GBP")
      setClientCurrency("GBP")
    else if (currentUser?.user?.ClientCurrency === "CAD")
      setClientCurrency("CAD")
    else
      setClientCurrency("")
  }
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const refillMainWallet=()=>{
    setBoLoader(true);
    cardholdercardsObj.refillbalance(currentUser.user.UserId).then((resp)=>{
      if(resp)
      {
        setBoLoader(false);
            let msgBody = resp?.response?.data?.message
              ? resp?.response?.data?.message
              : "Account Refilled SuccessFully";
            let errIcon = "icon-success";
            errorAndSuccessModl("Success", msgBody, errIcon);
      }


    }).catch((error)=>{
      setBoLoader(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
    });
  }
  const hideallpopups=()=>{
    setDialougeModalShow(false);
    window.location.reload();
  }
  const suspendCard =()=>{
    setBoLoader(true);
    let TokenId=props?.cardnumber;
    let ClientId=currentUser?.user?.ClientId;
    let Description="Suspended From Card";
    let BlockAction= "";
    let CardCurrency=currentUser?.user?.ClientCurrency;
    let UserIdCard=currentUser?.user?.UserId;
    let OperatorUserId=currentUser?.user?.UserId;
    let IsBlock=true;
    cardholdercardsObj
    .suspendCard(TokenId, OperatorUserId, UserIdCard, IsBlock, ClientId, Description,BlockAction, CardCurrency)
    .then((res) => {
      setBoLoader(false);
      if (res && res.data && res.data.payload) {
        let msgBody = res?.response?.data?.message
              ? res?.response?.data?.message
              : "Card Suspended SuccessFully";
            let errIcon = "icon-success";
            setDialougeModalShowStatus(true);
            sessionStorage.removeItem("user");
      }
    })
    .catch((error) => {
      setBoLoader(false);
      let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message

        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
    });
  }
  return (
    <div className={dynamicClass}>
      <BoLoader loading={boloader}/>
      <div className={dynamicClass2}>
        
        <KapedVirtualAssistant />
        <h3>Card Details</h3>
        <ul className="ul-card-details-popup mt-20">
          <li className="d-flex justify-content-between mb-20">
            <label>Status</label>
            <span>
           
              <label className="switch" >
              { setpermission!=="false" &&(<img src={eyeOn}/>)}
              { setpermission!=="true" &&(<img src={eyeOff}/>)}
              
                <input
                  type="checkbox"
                  onChange={() => setModaldeactivateshow(true)}

                />
              
              </label>
             {setpermission!=="false" ? "In Active" : "Active"}
           
            </span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>Balance</label>
            {/* <span> {props.ClientBalance}  {PaymentProcessor==="MSwipe" ?"USD":"EUR"}</span> */}
            {/* <span> {props.ClientBalance}  {clientCurrency}</span> */}
            <span>
              <CurrencyFormat value={currentUser?.user?.CardFlow === "CREDIT" ?
                (props.creditBalance - props.ClientBalance) > 0 ? props.creditBalance - props.ClientBalance : 0 : props.ClientBalance}
                displayType={'text'} thousandSeparator={true} />&nbsp;{clientCurrency}
            </span>

          </li>
          {currentUser?.user?.IssuerName === "IssuerB4B" &&(
            <li className="d-flex justify-content-between mb-20">
            <label>Pending Balance</label>
            <span>
              <CurrencyFormat value={props?.pendingbalance ? props?.pendingbalance:"-"}
                displayType={'text'} thousandSeparator={true} />&nbsp;{clientCurrency}
            </span>

          </li>
          )
          }
          
          <li className="d-flex justify-content-between mb-20">
            <label>Token ID</label>
            <span>{props.cardnumber}</span>
          </li>
          {
            !currentUser?.user?.IsPhysicalCard &&(
              <li className="d-flex justify-content-between mb-20">
              <label>Valid Thru</label>
              <span>{ValidThru}</span>
            </li>
            )
          }
          <li className="d-flex justify-content-between mb-20">
            <label>Card Type</label>
            {currentUser?.user?.IsPhysicalCard ? (<span>Physical</span>) : (<span>Virtual</span>)}
          </li>
          {
            currentUser?.user?.IsPhysicalCard &&(
              <div>
                {
                  ["EUR","GBP"].includes(currentUser?.user?.ClientCurrency) &&(<li className="d-flex justify-content-between mb-20">
                  <label>Card Pin</label>
                  {
                      !IconShownPin && (
                        <span>
                          <i className="icon-show" onClick={showHideIconPin}></i>****
                        </span>
                      )
                    }
                    {
                      IconShownPin && (
                        <span>
                          <i className="icon-hidden" onClick={showHideIconPin}></i>{cardpin}
                        </span>
                      )
                    }
                  {/* <span>{cardpin}</span> */}
                </li>)
                }
         {["EUR","GBP","USD"].includes(currentUser?.user?.ClientCurrency) && currentUser?.user?.IssuerName =="IssuerB4B" &&(
          <li>
            <div className="form-group submit-field d-flex mt-20">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  data-bs-target="#changePin"
                  data-bs-toggle="modal"
                  onClick={() => setModalrefillamountshow(true)}
                  disabled={props?.pendingbalance==0}
                >
                  Top Up Balance
                </Link>
              </div>
          </li>
         )} 
          
          </div>
            )
          }
          
          {currentUser?.user?.PaymentProcessor === "MSwipe" ?
            (!currentUser?.user?.IsPhysicalCard && <li className="d-flex justify-content-between mb-20">
              <label>CVV</label>
              {
                !IconShown && (
                  <span>
                    <i className="icon-show" onClick={showHideIcon}></i>***
                  </span>
                )
              }
              {
                IconShown && (
                  <span>
                    <i className="icon-hidden" onClick={showHideIcon}></i>{Cvv}
                  </span>
                )
              }

            </li>)
            :
            (currentUser?.user?.IsPhysicalCard && 
              
["EUR","GBP"].includes(currentUser?.user?.ClientCurrency)   && (
  <li className="d-flex justify-content-between mb-20">
<label>Card Pin</label>
              {
                !IconShown && (
                  <span>
                    <i className="icon-show" onClick={showHideIcon}></i>****
                  </span>
                )
              }
              {
                IconShown && (
                  <span>
                    <i className="icon-hidden" onClick={showHideIcon}></i>{Cvv}
                  </span>
                )
              }

            </li>)
)
              }
              

        </ul>
       {
        currentUser?.user?.PaymentProcessor==="MSwipe" && currentUser?.user?.IsPhysicalCard && (
          <Fragment>
            {
              (currentUser?.user?.IssuerName !=="IssuerSP" &&currentUser?.user?.ClientCurrency ==="USD" ) &&(
                <div className="form-group submit-field d-flex mt-20">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  data-bs-target="#changePin"
                  data-bs-toggle="modal"
                  onClick={() => setModalchangeshow(true)}
                >
                  Change PIN
                </Link>
              </div>
              )
            }
         
          </Fragment>
         
         
         )
       } 
        {/* <div className="form-group submit-field d-flex  mt-1">
          <Link
            to="#"
            className="btn text-btn justify-content-center w-100"
            data-bs-target="#resetPin"
            onClick={() => setModalresetshow(true)}
            data-bs-toggle="modal"
          >
            <i className="icon-lock"></i>Reset PIN
          </Link>
        </div> */}
        <hr />
        {/* <div className="mt-24 bo-help">
          <h3>Help</h3>
          <p>
            For further querires Admin will reach out via email if more details
            are required we will contact you.
          </p>
          <div className="a-arrow-btn mt-20">
            <Link to="/help"
              onClick={() => {
                document
                  .getElementById("helpNavLink")
                  .classList.add("active");
                document
                  .getElementById("cardsNavLink")
                  .classList.remove("active");
              }}>
              Submit Your Query <span className="icon-btn_arrow_right"></span>
            
            </Link>
          </div>
        </div> */}
       {props?.calledfrom==="dashboard" ?(<></>):(<><Help/></>) } 
      </div>
      <ModelPopup
        key={1}
        show={modalchangeshow}
        onHide={() => setModalchangeshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Change PIN</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to change Card PIN?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              class="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModalchangeshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              class="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                showHideModalChange();
                                setModalchangeshow(false);
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={2}
        show={modalresetshow}
        onHide={() => setModalresetshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to reset Card PIN?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              class="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModalresetshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              class="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                showHideModalReset();
                                setModalresetshow(false);
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
       <ModelPopup
        key={4}
        show={modalrefillamountshow}
        onHide={() => setModalrefillamountshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to top up from your pending balance?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              class="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModalrefillamountshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              class="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                refillMainWallet();
                                setModalrefillamountshow(false);
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={3}
        show={modaldeactivateshow}
        onHide={() => setModaldeactivateshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    {setpermission !== "false" ? (<form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Are you sure you want to deactivate your card?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setModaldeactivateshow(false);
                                localStorage.setItem("activeInactiveSendmoney", "true");
                                setpermission = localStorage.getItem("activeInactiveSendmoney");

                              }
                              }
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                suspendCard();
                                setModaldeactivateshow(false);
                                localStorage.setItem("activeInactiveSendmoney", "false");
                                setpermission = localStorage.getItem("activeInactiveSendmoney");
                                
                              }}

                            >

                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>) : (
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to deactivate your card?</p>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <Link
                                to="#"
                                className="btn btn-large"
                                data-bs-dismiss="modal"
                                onClick={() => { setModaldeactivateshow(false); 
                                  localStorage.setItem("activeInactiveSendmoney", "false"); 
                                  setpermission = localStorage.getItem("activeInactiveSendmoney"); 
                                }}

                              >
                                No
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-outline"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  suspendCard();
                                  setModaldeactivateshow(false);
                                  localStorage.setItem("activeInactiveSendmoney", "true");
                                  setpermission = localStorage.getItem("activeInactiveSendmoney");
                                 
                                }}

                              >

                                Yes
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />

      <ChangePinCardDetails
        key={8}
        show={modalchangepinshow}
        onHide={() => setModalchangepinshow(false)}
        calledfrom={calledfrom}
        tokenId={props.cardnumber}
      />

      <ModelPopup
        key={7}
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            {showTwoFA && (
              <TwoFaForm
                userEmail={currentUser.user.Email}
                userId={currentUser.id}
                userMobile={currentUser.user.PhoneNumber ?? ""}
                pagename={"cardholderresetpin"}
                onHide={() => setCnfrmModalShow(false)}
              />
            )}
          </Fragment>,
        ]}
      />
        <DialoguePopup
          key={1}
          show={dialougeModalShow}
          onHide={() => {setDialougeModalShow(false); if(MdlIcon=="icon-success") props?.onHide();}}
          modelheading={modelhdng}
          modelbody={modelbdy}
          modelicon={MdlIcon}
          backdrop="static"
          keyboard={false}
          hideallpopups={hideallpopups}
        />
        
        <ModelPopup
        key={3}
        show={dialougeModalShowStatus}
        onHide={() =>{ setDialougeModalShowStatus(false); sessionStorage.removeItem("user"); window.location.reload(); }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                   <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Card Suspended SuccessFully!</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                           
                            <Link
                              to="#"
                              className="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                sessionStorage.removeItem("user"); window.location.reload(); 
                              }}

                            >

                              OK
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default CardHolderDetails;
