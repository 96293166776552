import { Button } from "bootstrap";
import React, { useState, useEffect, Fragment } from "react";
import ReactExport from "react-export-excel";
 import xls from "../../src/assets/images/xls.svg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportToExcel = props => {
  const ExcelColmn = () => {
    if (props.calledfrom === "transaction") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="MERCHANT NAME" value="merchantName" />
            <ExcelColumn label="STATUS" value="status" />
            <ExcelColumn label="AMOUNT" value="authAmount" />
            <ExcelColumn label="DATE" value="dateOfTransaction" />
            {/* <ExcelColumn label="REF #" value="retrievalReferenceNumber" />
            <ExcelColumn label="DESCRIPTION" value="description" /> */}
          </ExcelSheet>
        </ExcelFile>
      );
    } else if (props.calledfrom === "sendmoney") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
          <ExcelColumn label="NAME" value="name" />
            <ExcelColumn label="TOKEN ID" value="tokenId" />
            <ExcelColumn label="DATE" value="createdDate" />
            <ExcelColumn label="AMOUNT" value="amount" />
          </ExcelSheet>
        </ExcelFile>
      );
    } else {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export To Excel{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={"No Data"}></ExcelSheet>
        </ExcelFile>
      );
    }
  };
  return <ExcelColmn />;
};

export default ExportToExcel;
