import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL_Cardholders = Env.CardHolderApiUrl; //"http://localhost:8000/api/cardholder/";
const API_URL = Env.CardsApiUrl;//"http://localhost:8000/api/card/";
const API_URL_Transaction = Env.TransactionApiUrl;//"http://localhost:8000/api/card/";
const changeCardHolderPin = (Pin,
  TokenId,
  UserId,) => {
  return axios
    .post(
      API_URL + "setupdatepin",
      {
        Pin,
        TokenId,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getCardDetails = () => {
  return axios
    .get(API_URL_Cardholders + "getCardDetails", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getCardLimits = (UserId) => {
  return axios
    .post(API_URL_Transaction + "getcardtransactionlimit", {UserId},{ headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getCardHolderDetails = () => {
  return axios
    .get(API_URL_Cardholders + "getCardHolderDetails", {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const searhGridCardHoldersCards = (
  name,
  tokenid,
  dateFrom,
  dateTo,
  startIndex
) => {
  return axios
    .post(
      API_URL_Cardholders + "cardholderscards",
      {
        name,
        tokenid,
        dateFrom,
        dateTo,
        startIndex,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getCardNumber = (userId) => {
  return axios
    .get(API_URL_Cardholders + "getcarddetaildashboard?userId="+userId, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
};
const getSuperAdminBalance = (userId) => {
  return axios
    .post(API_URL + "getwalletbalance?userId="+userId,{}, {
      headers: authHeader()
    })
    .then((res) => {
      return res;
    });
};
const getBalancePhysical = (userId) => {
  return axios
    .post(API_URL + "getcardbalance?userId="+userId,{}, {
      headers: authHeader()
    })
    .then((res) => {
      return res;
    });
};
const getCardCvv = (userId) => {
  return axios
    .get(API_URL + "getcardcvv?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const creditLimitCalculation = (userId) => {
  return axios
    .post(API_URL_Transaction + "creditlimitcalculation?userId=" + userId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getpinb4b = (userId) => {
  return axios
    .post(API_URL_Cardholders + "getpinb4b?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const changeCardHolderPinIssuerB = (NewPin,
  UserId,
  ) => {
  return axios
    .post(
      API_URL_Cardholders + "setpinIssuerG",
      {
        NewPin,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const refillbalance = (userId) => {
  return axios
    .post(API_URL_Transaction + "refillbalance?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const suspendCard = (TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,BlockAction,CardCurrency) => {
  return axios
    .post(
      API_URL + "blockunblockcard",
      {TokenId,OperatorUserId,UserIdCard,IsBlock,ClientId,Description,BlockAction,CardCurrency},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const changeCardHolderPinIssuerB4B = (NewPin,
  UserId,
  ) => {
  return axios
    .post(
      API_URL_Cardholders + "createpinb4b",
      {
        NewPin,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};

const cardholdercardsObj = {
  changeCardHolderPin,
  getCardDetails,
  getCardLimits,
  getCardHolderDetails,
  searhGridCardHoldersCards,
  getCardNumber,
  getSuperAdminBalance,
  getCardCvv,
  getBalancePhysical,
  creditLimitCalculation,
  getpinb4b,
  changeCardHolderPinIssuerB,
  refillbalance,
  suspendCard,
  changeCardHolderPinIssuerB4B
};
export default cardholdercardsObj;
