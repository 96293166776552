import React, { useState,useEffect, Fragment } from "react";
import creditlimit from "../../assets/images/icons/credit-limit.svg";
import onlinelimit from "../../assets/images/icons/online-limit.svg";
import transferlimit from "../../assets/images/icons/transfer-limit.svg";
import atmlimit from "../../assets/images/icons/atm-limit.svg";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import { useDispatch, useSelector } from "react-redux";
import BoLoader from "../../helpers/BoLoader";

const CardLimit = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));
  const [limits, setlimits] = useState();
  const [clientCurrency, setClientCurrency] = useState("");
  const [boloader, setBoLoader] = useState(false);
  const [ClientBalance, setClientBalance] = useState();
  const [creditcardlimits, setCreditcardlimits] = useState();


  useEffect(() => {
    getCardLimits();
    checkClientCurrency();
   
  }, [])
  useEffect(()=>{
    if(currentUser?.user?.CardFlow==="CREDIT"){
    if (currentUser?.user?.IsPhysicalCard) {
      cardholdercardsObj.getBalancePhysical(UserId).then(
        (response) => {
          if(response && response.data && response.data.payload){
          setClientBalance(response.data.payload.result.item1.balance);
          }
        },
        (error) => {
        
        }
      );
    }
    else  {
    cardholdercardsObj.getSuperAdminBalance(UserId).then(
      (response) => {
        if(response && response.data && response.data.payload)
        setClientBalance(response.data.payload.result.item1.balance);
      },
      (error) => {
      
      }
    );
    }
    cardholdercardsObj.creditLimitCalculation(UserId).then((resp)=>{
      setCreditcardlimits(resp?.data?.payload?.result);
    }).catch((error)=>{

    });

  }
  },[])
  const getCardLimits = () => {
    setBoLoader(true);
    cardholdercardsObj.getCardLimits(UserId).then((res)=>{
      if(res){
  setBoLoader(false);
  setlimits(res?.data?.payload?.result?.item1);
}
    }).catch(()=>{
      setBoLoader(false);
    });
    
  };
  const percentage=(partialValue, totalValue)=> {
    let value=((100 * partialValue) / totalValue);
   let returnvalue= Math.round(value * 100) / 100;
    return returnvalue;
 } 
 const percentageString=(partialValue, totalValue)=> {
  let value=((100 * partialValue) / totalValue);
 let returnvalue= Math.round(value * 100) / 100;
 console.log(String(returnvalue+"%"))
  return String(returnvalue+"%");
} 
 const checkClientCurrency=()=>{
   if(currentUser?.user?.ClientCurrency==="USD")
   setClientCurrency("$")
   else if(currentUser?.user?.ClientCurrency==="EUR")
   setClientCurrency("€")
   else if(currentUser?.user?.ClientCurrency==="GBP")
   setClientCurrency("£")
   else if(currentUser?.user?.ClientCurrency==="CAD")
   setClientCurrency("C$")
  else 
   setClientCurrency("")
 }
  return (
    <div className="col-xl-6 col-md-6 col-sm-6">
      <div className="row">
      <BoLoader loading={boloader} />
      {
        currentUser?.user?.CardFlow==="CREDIT" ?(
          <>
          <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
            <span className="icon-Credit-Limit">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              {/* <img src={creditlimit} alt="crdtlmt" /> */}
            </div>
            <h4>{clientCurrency}{ClientBalance ? ClientBalance:0}</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{width: percentageString(ClientBalance ? ClientBalance:0,creditcardlimits?.totalLimit)}}
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats mb-1">
              <span>Credit Limit</span>
              <span>{ClientBalance<0? (percentage(ClientBalance ? ClientBalance:0,creditcardlimits?.totalLimit)):0}%</span>
            </div>
            <p>{clientCurrency}{ClientBalance ? ClientBalance:0}</p>
          </div>
        </div>
          </>
        ):(<>
        {/* <div className="col-6">
          {currentUser?.user?.IsPhysicalCard &&(
  <div className="bo-card-box bg-blush">
  <div className="mb-3">
  <span className="icon-Credit-Limit">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  
  </div>
  <h4>{limits?.atmTransactionCount ? limits?.atmTransactionCount:"0"}</h4>
  <div className="progress w-100 mt-2 mb-2">
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{width: percentageString(limits?.atmTransactionCount ? limits?.atmTransactionCount:0,3)}}
    ></div>
  </div>
  <div className="d-flex align-items-center bo-card-stats mb-1">
    <span>ATM Transaction Daily Limit</span>
    <span>{percentage(limits?.atmTransactionCount ? limits?.atmTransactionCount:0,3)}%</span>
  </div>
  <p>3</p>
</div>
          )}
        
        </div> */}
        {
          currentUser?.user?.IsPhysicalCard &&(
            <Fragment>
        {/* <div className="col-6">
           <div className="bo-card-box bg-blush">
           <div className="mb-3">
           <span className="icon-Online-Limit">
               <span className="path1"></span>
               <span className="path2"></span>
             </span>
             
           </div>
           <h4>{clientCurrency }{limits?.atmTransactionAmount?limits.atmTransactionAmount:"0" }</h4>
           <div className="progress w-100 mt-2 mb-2">
             <div
               className="progress-bar"
               role="progressbar"
               aria-valuenow="50"
               aria-valuemin="0"
               aria-valuemax="100"
               style={{width: percentageString(limits?.atmTransactionAmount ? limits?.atmTransactionAmount:0,75000)}}

             ></div>
           </div>
           <div className="d-flex align-items-center bo-card-stats">
             <span>ATM  Access (Per Year)</span>
             <span>{percentage(limits?.atmTransactionAmount ? limits?.atmTransactionAmount:0,75000)}%</span>
           </div>
           <p>{clientCurrency}75,000</p>
         </div>
       
         
        </div> */}
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
            <span className="icon-Transfer-Limit">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </div>
            <h4>{clientCurrency}{limits?.loadCardLimit ? limits?.loadCardLimit :"0"}</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{width: percentageString(limits?.loadCardLimit ? limits?.loadCardLimit:0,125000)}}

              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats">
              <span> Life Time Load Limit</span>
              {
                currentUser?.user?.IssuerName==="IssuerB4B" &&  currentUser?.user?.ClientCurrency=="USD" &&(
                  <span>{percentage(limits?.loadCardLimit ? limits?.loadCardLimit:0,125000)}%</span>

                )
              }
               {
                currentUser?.user?.IssuerName==="IssuerB4B" &&  currentUser?.user?.ClientCurrency=="GBP" &&(
                  <span>{percentage(limits?.loadCardLimit ? limits?.loadCardLimit:0,75000)}%</span>

                )
              }

              {
                currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="EUR" &&(
                  <span>{percentage(limits?.loadCardLimit ? limits?.loadCardLimit:0,100000)}%</span>

                )
              }
            </div>
            {
              currentUser?.user?.IssuerName==="IssuerB4B"&& currentUser?.user?.ClientCurrency=="EUR" &&(
              <p>{clientCurrency}100,000</p>
              )
            }
            {
              currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="USD" &&(
              <p>{clientCurrency}125,000</p>
              )
            }
            {
              currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="GBP" &&(
              <p>{clientCurrency}75,000</p>
              )
            }
            
          </div>
        </div>
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
            <span className="icon-ATM-Limit">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
              {/* <img src={atmlimit} alt="atmlmt" /> */}
            </div>
            <h4>{clientCurrency}{limits?.cardPurchaseLimit ? limits?.cardPurchaseLimit :"0"}</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{width: percentageString(limits?.cardPurchaseLimit ? limits?.cardPurchaseLimit:0,100000)}}
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats">
              <span>Life Time Spend Limit</span>
             
             {
                currentUser?.user?.IssuerName==="IssuerB4B"&&currentUser?.user?.ClientCurrency=="EUR"  &&( 
                  <span>{percentage(limits?.cardPurchaseLimit ? limits?.cardPurchaseLimit:0,125000)}%</span>

                )
              }
              {
                currentUser?.user?.IssuerName==="IssuerB4B"&& currentUser?.user?.ClientCurrency!="EUR"  &&(
                  <span>{percentage(limits?.cardPurchaseLimit ? limits?.cardPurchaseLimit:0,100000)}%</span>

                )
              }
            </div>
            {
              currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="EUR"  &&(
                <p>{clientCurrency}100,000</p>
              )
            }
            {
              currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="USD" &&(
                <p>{clientCurrency}125,000</p>
              )
            }
             {
              currentUser?.user?.IssuerName==="IssuerB4B" && currentUser?.user?.ClientCurrency=="GBP" &&(
                <p>{clientCurrency}75,000</p>
              )
            }
           
          </div>
        </div>
            </Fragment>
          )
        }
        
        </>)
      }
        
      </div>
    </div>
  );
};

export default CardLimit;
