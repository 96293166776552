import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import emptyclient from "../../assets/images/icons/clients-empty.svg";
import transactionService from "../../services/transaction.service";
import { useSelector } from "react-redux";
import ExportToExcel from "../../helpers/ExportToExcel";
import CurrencyFormat from 'react-currency-format';

const SearchGridInputData = [
  {
    Name: "",
    UserId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
  },
];
var fontStyle = {
  textTransform: "uppercase",
};

const SearchGridSendMoney = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(String(currentUser.user.UserId));
  const [totalCount, setTotalCount] = useState(0);
  const [PaymentProcessor, setPaymentProcessor] = useState(String(currentUser.user.PaymentProcessor));
  const [clientCurrency, setClientCurrency] = useState("");

  //Export To Excel
  const [dataset, setDataset] = useState();
  useEffect(() => {
    SearchClickHandler(null, true);
    checkClientCurrency();
  }, []);
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e,isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    // if (isFromUseEffect) {
    //   setModalShow(true);
    // }
    // else
    setModalShow(false);
    setSearchDisabled(false);
    transactionService
      .searhGridSendMoney(
        name,
        UserId,
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            // if(isFromUseEffect)
            // setModalShow(true);
            // else
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload.result.pageModel);
              setDataset(resp.data.payload.result.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const showClientDetails = (id) => {
    history.push("/clientDetails", id);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        id,
        name,
        tokenId,
        amount,
        createdDate,
      } = data; //destructuring
      return (
        <tr key={id}>
          <td style={fontStyle}>{name}</td>
          <td style={fontStyle}>{tokenId}</td>
          <td>
            <DateFormat date={createdDate} />
          </td>
          <td> 
            <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} 
            renderText={value => <div>{value}&nbsp;{clientCurrency}</div>}/>
            </td>
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const checkClientCurrency=()=>{
    if(currentUser?.user?.ClientCurrency==="USD")
    setClientCurrency("USD")
    else if(currentUser?.user?.ClientCurrency==="EUR")
    setClientCurrency("EUR")
    else if(currentUser?.user?.ClientCurrency==="GBP")
    setClientCurrency("GBP")
    else if(currentUser?.user?.ClientCurrency==="CAD")
    setClientCurrency("CAD")
   else 
    setClientCurrency("")
  }
  return (
    <Fragment>
      <div className="row align-items-center mt-20">
        <div className="col-6">
          <h3>Send Money History</h3>
        </div>
        <div className="col-sm-6 toRight">
            <ExportToExcel 
            Dataset={dataset}
            Name="SendMoney"
            calledfrom="sendmoney"
            />
          </div>
      </div>
      <div className="">
        <div className="tab-content" id="myTabContent2">
          <div
            className="tab-pane fade show active"
            id="allClients"
            role="tabpanel"
            aria-labelledby="allClients-tab"
          >
            {/* no client found  */}
            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
              <div className="text-center">
                <span className="icon-clients-empty">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </span>
                <h3>Empty Clients Listing</h3>
                <h6>There is no Client(s) available for now.</h6>
              </div>
            </div>
            <Fragment>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row row-search">
                  <div className="col-xl-12 col-12">
                    <div className="form-group">
                      <div className="form-controls">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={props.placeholdervalue}
                          onClick={handleInputSearchClick}
                          disabled={SearchInputDisabled}
                        />
                        <span className="icon-search _ico"></span>
                        {/* search box  */}
                        <div
                          className="bo-cardsearch"
                          style={{ display: modalShow ? "" : "none" }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                              <div className="form-group form-search mr-20">
                                <label>Name</label>
                                <div className="form-controls">
                                  <input
                                    type="text"
                                    value={name}
                                    onChange={nameChangeHandler}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date From</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={dateFrom}
                                    onchangedate={dateFromHandler}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date To</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={dateTo}
                                    onchangedate={dateToHandler}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Client Name</label>
                              <div className="form-controls">
                              </div>
                            </div>
                          </div> */}
                            <div className="col-md-12">
                              <div className="form-group submit-field form-search">
                                <button
                                  id="searchBtnClients"
                                  className="btn btn-large mr-19"
                                  onClick={SearchClickHandler}
                                >
                                  Search
                                </button>
                                <button
                                  className="btn btn-outline"
                                  onClick={ClearSearchData}
                                >
                                  Clear Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </div>
                </div>
              </form>
            </Fragment>
          </div>

          {searchGridSuccess && (
            <div className="table-wrap" ref={searchTbl}>
              <table
                className="table table-borderless bo-table table-cards"
                onScroll={tblScrollHandler}
                onWheel={tblScrollHandler}
              >
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          )}
          {loadingData ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : totalCount === 0 ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg ">
              <div className="text-center">
                <span className="icon-deposit-emptystate">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                  <span className="path7"></span><span className="path8"></span>
                  <span className="path9"></span><span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <span className="icon-clients-empty">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </span> */}
                {/* <img src={emptyclient} /> */}
                <h3>Empty Send Money Listing</h3>
                <h6 className="mt-16">There are no Transactions available for now.</h6>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SearchGridSendMoney;
