import { useState, Fragment, useEffect } from "react";
// import DonutChart from "../../helpers/DonutChart";
// import chartsImg from "../../assets/images/chart.svg";
import { useSelector } from "react-redux";
// import viewAllImg from "../../assets/images/icons/arrow-right.svg";
// import rcvngImg from "../../assets/images/icons/receiving.svg";
// import wdrwImg from "../../assets/images/icons/withdraw.svg";
// import trnsfrImg from "../../assets/images/icons/transfer.svg";
// import stackedCrdsImg from "../../assets/images/stacked-cards.png";
import BoLoader from "../../helpers/BoLoader";
import cardHolderService from "../../services/cardholder.service";
// import { Env } from "../../enviroment/environment";
import CardDetails from "./CardDetails";
import useWindowDimensions from "../../helpers/WindowWidth";
import SearchGridCardTransactions from "./SearchGridCardTransactions";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import KapedVirtualAssistant from "./KapedVirtualAssistant";
// const API_URL = Env.CardsApiUrl;
// var colorscheme;
const CardHolderTransactions = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));

  const [transContent, settransContent] = useState();
  const { height, width } = useWindowDimensions();
  const [boloader, setBoLoader] = useState(false);
  const [transDataSuccessful, settransDataSuccessful] = useState(false);
  const [limits, setlimits] = useState();
  useEffect(() => {
    setBoLoader(true);
    settransDataSuccessful(true);
    cardHolderService.gettransactiondata().then(
      (response) => {
        setBoLoader(false);
        settransContent(response.data.payload);
        settransDataSuccessful(true);
      },
      (error) => {
        setBoLoader(false);
      }
    );
  }, []);
  useEffect(() => {
    getCardLimits();
   
  }, [])
  const getCardLimits = () => {
    setBoLoader(true);
    cardholdercardsObj.getCardLimits(UserId).then((res)=>{
if(res){
  setBoLoader(false);
  setlimits(res?.data?.payload?.result?.item1);
}
    }).catch(()=>{
      setBoLoader(false);

    });
    
  };
  // useEffect(() => {
  //   if (currentUser) {
  //     var themename = currentUser.user.ThemeName;
  
  //     if (themename === "theme1") {
  //       colorscheme= ["#89455E", "#E0DCDC"]
  //     }
  //     if (themename === "theme2") {
  //       colorscheme= ["#175873","#D0E7E0"]
  //     }
  //     if (themename === "theme3") {
  //       colorscheme= ["#0077B6","#90E0EF"]
  //     }
  //     if (themename === "theme4") {
  //       colorscheme= ["#723D46","#C9CBA3"]
  //     }
  //     if (themename === "theme5") {
  //       colorscheme=["#B07D62","#EDC4B3"]
  //     }
  //     if (themename === "theme6") {
  //       colorscheme= ["#EA5455","#FFE49B"]
  //     }
  //     if (themename === "theme7") {
  //       colorscheme= ["#0C2D48","#B1D4E0"]
  //     }
  //     if (themename === "theme8") {
  //       colorscheme= ["#433673","#05F2AF"]
  //     }
  //     if (themename === "theme9") {
  //       colorscheme= ["#433673","#05F2AF"]
  //     }
  //   }
  //   else{
  //     colorscheme= ["#89455E", "#E0DCDC"]
  //   }
  // }, []);
  
 
  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <BoLoader loading={boloader} />
        <section>
          <div>
            <form onSubmit={(e) => e.preventDefault}>
              <div className="row align-items-center row-head-clients">
                {/* <div className="col-xl-9 col-sm-9 col-12">
                  <h3>Overall Transactions</h3>
                </div> */}
                {/* <div className="col-xl-3 col-sm-3 col-12 input-hide">
                  <form>
                    <div className="form-group mb-0">
                      <div className="form-controls">
                        <input
                          className="form-control right-icon"
                          placeholder="Last Week"
                        />
                        <span className="icon-calender _ico icon-right"></span>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </form>
                </div> */}
                {/* <div className="col-12">
                  <div className="col-stats col-stats-chart mb-40 mt-20">
                    {transDataSuccessful && (
                      <div className="row align-items-center"> */}
                        {/* <div className="col-md-4 col-12">
                          <div className="chart-wrap">
                            {transContent ? (
                              <DonutChart
                                chartdata={{ ...transContent }}
                                colorscheme={colorscheme}
                                className="donut-chart"
                              />
                            ) : (
                              <img src={chartsImg} alt="chartsImg"></img>
                            )}
                          </div>
                        </div> */}
                        {/* <div className="col-md-8 col-12">
                          <div className="chart-legends chart-legends-transaction">
                            <div className="row">
                              <div className="col-md-4 col-6 mb-20">
                                <p>Grocery</p>
                                <h5>
                                  <span className="bg-russian"></span>{limits?.groceryAmount?limits.groceryAmount:"0" }
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>ATM Withdrawal</p>
                                <h5>
                                  <span className="bg-pink"></span>{limits?.atmTransactionAmount?limits.atmTransactionAmount:"0" }
                                </h5>
                              </div>
                              <div className="col-md-4 col-6">
                                <p>Bank Transfer</p>
                                <h5>
                                  <span className="bg-dustpink"></span>{limits?.bankTransfer?limits.bankTransfer:"0" }
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>Travel</p>
                                <h5>
                                  <span className="bg-prim"></span>{limits?.travelAmount?limits.travelAmount:"0" }
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>Bills and Utilities</p>
                                <h5>
                                  <span className="bg-amour"></span>{limits?.billAndUtilitiesAmount?limits.billAndUtilitiesAmount:"0" }
                                </h5>
                              </div>
                              <div className="col-md-4 col-6">
                                <p>Others</p>
                                <h5>
                                  <span className="bg-submarine"></span>{limits?.others?limits.others:"0" }
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      {/* </div>
                    )}
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </section>

        <div className="row">
          {width <= 991 && (
            <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
              <div className="bo-right-sidebar-wrap">
              <KapedVirtualAssistant/>
                <div className="mb-20">
                  <h3>
                    Your Cards<span className="bo-badge bo-badge-large">1</span>
                  </h3>
                </div>
                <CardDetails cht="Y" />
              </div>
            </div>
          )}
        </div>
        <SearchGridCardTransactions
          placeholdervalue="Search Card Holder Transactions"
          headersdata={[
            "DATE",
            "MERCHANT NAME",
            "STATUS",
            // "CURRENCY",
            "AMOUNT",
            // "REF #",
            "VIEW",
          ]}
          screenname="cardholdertransactions"
        />
      </div>
      {width > 991 && (
        <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
          <div className="bo-right-sidebar-wrap">
          <KapedVirtualAssistant/>
            <div className="mb-20">
              <h3>
                Your Cards<span className="bo-badge bo-badge-large">1</span>
              </h3>
            </div>
            <CardDetails cht="Y" />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CardHolderTransactions;
